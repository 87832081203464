import React, { Fragment } from 'react';
import { Icon, Divider, Container } from 'semantic-ui-react';

const AppStoreLogos = () => {
  return (
    <Fragment>
      <Container textAlign='center'>
        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          <Icon name='android' size='massive' color='olive' />
        </a>

        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          <Icon name='apple' size='massive' color='teal' />
        </a>
      </Container>

      <Divider />
    </Fragment>
  );
};

export default AppStoreLogos;

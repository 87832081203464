import React, { Fragment } from 'react';
import { Menu } from 'semantic-ui-react'; // Add Button
import { NavLink } from 'react-router-dom';

const SignedOutMenu = ({ signIn, register }) => {
  return (
    <Fragment>
      <Menu.Item as={NavLink} to={'/info/districts'} name='School Districts' />
      <Menu.Item as={NavLink} to={'/info/schools'} name='Schools' />
      <Menu.Item as={NavLink} to={'/info/teachers'} name='Teachers' />
      <Menu.Item as={NavLink} to={'/about/ourStory'} name='Our Story' />
      <Menu.Item as={NavLink} to={'/info/faq'} name='FAQ' />
      {/* <Menu.Item position='right'>
        <Button onClick={signIn} color='red' content='Login' />
        <Button
          onClick={register}
          basic
          inverted
          color='red'
          content='Register'
          style={{ marginLeft: '0.5em' }}
        />
      </Menu.Item> */}
    </Fragment>
  );
};

export default SignedOutMenu;

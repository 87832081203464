import React, { Fragment } from 'react';
import { Image, Menu, Dropdown, Container, Button } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';

const SignedinMenu = ({ signOut, profile, auth }) => {
  return (
    <Fragment>
      <Menu.Item>
        <Dropdown icon='info circle' pointing='top left' text='About'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to={'/about/info'}
              icon='info'
              text='Information'
            />
            <Dropdown.Item
              as={Link}
              to={'/about/ourStory'}
              icon='newspaper outline'
              text='Our Story'
            />
            <Dropdown.Item
              as={Link}
              to={'/team/jamie'}
              icon='handshake'
              text='Meet Our Team'
            />
            <Dropdown.Item
              as={Link}
              to={'/about/appPlayStore'}
              icon='app store'
              text='Download'
            />
            <Dropdown.Item
              as={Link}
              to={'/info/districts'}
              icon='building'
              text='School Districts'
            />
            <Dropdown.Item
              as={Link}
              to={'/info/privacyPolicy'}
              text='Privacy Policy'
              icon='shield'
            />
            <Dropdown.Item
              as={Link}
              to={'/info/termsService'}
              text='Terms & Service'
              icon='book'
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item as={NavLink} to={`/contact/${'feedback'}`} name='Feedback' />
      <Menu.Item as={NavLink} to={'/info/faq'} name={`Help`} />
      <Menu.Item
        as={NavLink}
        to={'/mySchool'}
        name={`School`}
        icon='graduation cap'
      />

      <Menu.Item position='right'>
        <Dropdown icon='id badge' pointing='top left' text='Passes'>
          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to={'/studentsOutOfClass'}
              icon='eye'
              text='Live! Passes'
            />
            <Dropdown.Item
              as={Link}
              to={'/passes'}
              text='Pass Archives'
              icon='address card'
            />
            <Dropdown.Item
              as={Link}
              to={'/passesRequests'}
              text='Approve/Deny Requests'
              icon='tasks'
            />
            <Dropdown.Item
              as={Link}
              to={'/sharedClassPass'}
              text='Class Pass'
              icon='share square outline'
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
      <Menu.Item>
        <Button
          as={Link}
          to={'/createPass'}
          floated='right'
          color='red'
          content='Go On Pass'
        />
      </Menu.Item>
      <Container>
        <Menu.Item position='right'>
          <Image
            avatar
            spaced='right'
            src={profile.photoURL || '/assets/user.png'}
          />
          <Dropdown pointing='top left' text={profile.displayName}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/profile/${auth.uid}`}
                text='My Profile'
                icon='user'
              />
              <Dropdown.Item
                as={Link}
                to={'/settings'}
                text='Settings'
                icon='settings'
              />
              <Dropdown.Item onClick={signOut} text='Sign Out' icon='power' />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Container>
      {'  '}
      <Menu.Item
        icon='comments outline'
        as={NavLink}
        position='right'
        to={'/askJamie'}
        name='Ask Jamie'
      />
    </Fragment>
  );
};

export default SignedinMenu;

//   <Menu.Item as={NavLink} to={'/passes'} name='Passes' />
//   <Menu.Item as={NavLink} to={'/passHistory'} name='Pass History' />

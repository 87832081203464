import React from 'react';
import { Grid, Header, Icon, Divider, Image } from 'semantic-ui-react';
import AppStoreFeatureList from '../appStore/AppStoreFeatureList';
import Features from '../appFeatures/FeaturesList';
import FollowUs from '../FollowUs';
import Footer from '../../nav/NavBar/Footer';

const TeacherInfoVisitor = ({ signIn }) => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='graduation cap' color='red' />
          Teachers
        </Header>
        <Divider />
        <Image src='/assets/carousel/easyPassApprove.jpg' />
        <Features />
        <AppStoreFeatureList />
      </Grid.Column>
      <Grid.Column width={6}>
        <Image src='/assets/phonePreview/viewStudentsXL.png' />
        <FollowUs />
      </Grid.Column>{' '}
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default TeacherInfoVisitor;

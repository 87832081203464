import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Segment,
  Header,
  Divider,
  Grid,
  Button,
  Icon
} from 'semantic-ui-react';
import DropZoneInput from './DropZoneInput';
import CropperInput from './CropperInput';
import { uploadProfileImage } from '../../userActions';
import { toastr } from 'react-redux-toastr';
import UserPhotos from './UserPhotos';

const actions = {
  uploadProfileImage
};

const mapState = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  loading: state.async.loading
});

const PhotosPage = ({ uploadProfileImage, profile, loading }) => {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null); // Holds image after Cropped

  // Delete the File from Memory where we created Preview for memory cleanup
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const handleUploadImage = async () => {
    try {
      await uploadProfileImage(image, files[0].name);
      handleCancelCrop();
      toastr.success('Succes', 'Photo uploaded');
    } catch (error) {
      console.log(error);
      toastr.error('Oops', 'Something went wrong');
    }
  };

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
  };

  return (
    <Segment.Group>
      <Segment
        textAlign='center'
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardLong.jpg'})`,
          padding: '30px'
        }}
      >
        <Header size='huge' color='yellow' inverted>
          <Icon name='help circle' />
          Your Photos
        </Header>
        <Divider />
        <UserPhotos profile={profile} />
        <Divider />
        <Grid>
          <Grid.Row />
          <Grid.Column width={4}>
            <Header inverted color='teal' sub content='Step 1 : Add Photo' />
            <DropZoneInput setFiles={setFiles} />
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4}>
            <Header sub inverted color='teal' content='Step 2 : Resize' />
            {files.length > 0 && (
              <CropperInput
                setImage={setImage}
                imagePreview={files[0].preview}
              />
            )}
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={4}>
            <Header
              sub
              inverted
              color='teal'
              content='Step 3 : Upload'
            />
            {files.length > 0 && (
              <Fragment>
                <div
                  className='img-preview'
                  style={{
                    minHeight: '200px',
                    minWidth: '200px',
                    overflow: 'hidden'
                  }}
                />
                <Button.Group>
                  <Button
                    loading={loading}
                    onClick={handleUploadImage}
                    style={{ width: '100px' }}
                    positive
                    icon='check'
                  />
                  <Button
                    disabled={loading} // User can't cancel mid Upload so disable button. Can delete image afterwards
                    onClick={handleCancelCrop}
                    style={{ width: '100px' }}
                    icon='close'
                  />
                </Button.Group>
              </Fragment>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment
        textAlign='center'
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
        }}
      />
    </Segment.Group>
  );
};

export default compose(
  connect(
    mapState,
    actions
  )
)(PhotosPage);

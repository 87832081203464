import React from 'react';
import { Grid, Header, Divider, Segment, Image, Icon } from 'semantic-ui-react';
import Price from './AppStoreFeatureList';
import TraditionalPass from './TraditionalPass';
import AppStoreDescription from './AppStoreDescription';
import Footer from '../../nav/NavBar/Footer';
import AppStoreLogos from './AppStoreLogos';

const AppStore = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='app store ios' color='red' />
          App Store & Play Store
        </Header>
        <Divider />
        <Segment>
          <Image src='/assets/carousel/getApp.jpg' />
          <AppStoreDescription />
          <AppStoreLogos />
          <TraditionalPass />
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}>
        <Image src='/assets/phonePreview/viewStudentsXL.png' />
        <Price />
      </Grid.Column>
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default AppStore;

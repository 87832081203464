import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { Menu, Container } from 'semantic-ui-react';
import { NavLink, withRouter} from 'react-router-dom';
import SignedOutMenu from '../Menus/SignedOutMenu';
import SignedinMenu from '../Menus/SignedinMenu';
import { openModal } from '../../modals/modalActions';

const actions = {
  openModal 
};

const mapState = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

class NavBar extends Component {
  // METHODS
  handleSignin = () => {
    this.props.openModal('LoginModal');
  };
  handleRegister = () => {
    this.props.openModal('RegisterModal');
  };
  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push('/');
  };

  render() {
    const { auth, profile } = this.props;
    const isLoggedin = auth.isLoaded && !auth.isEmpty;
    return (
      <Menu inverted fixed='top'>
        <Container>
          <Menu.Item as={NavLink} exact to={'/'} header>
            <img src='/assets/logo.png' alt='logo' />
            School Pass Live!
          </Menu.Item>
          {isLoggedin ? (
            <Fragment>
              <SignedinMenu
                profile={profile}
                signOut={this.handleSignOut}
                auth={auth}
              />
            </Fragment>
          ) : (
            <Fragment>
              <SignedOutMenu
                signIn={this.handleSignin}
                register={this.handleRegister}
              />
              <Menu.Item
                as={NavLink}
                to={`/contact/${'form'}`}
                name='Contact'
              />
            </Fragment>
          )}
        </Container>
      </Menu>
    );
  }
}

// Pass Properties to NavBar so have access to Router Props for Heiarchy.
// For Signout if Logged in.
export default withRouter(
  withFirebase(
    connect(
      mapState,
      actions
    )(NavBar)
  )
);

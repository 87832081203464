import React, { Fragment } from 'react';
import { Segment, Header, Icon } from 'semantic-ui-react';

const FollowUs = ({ size }) => {
  return (
    <Fragment>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        inverted
      >
        <Header>Follow Us</Header>
      </Segment>
      <Segment attached textAlign='center'>
        <div>
          <a
            href='https://www.facebook.com/School-Pass-Live-470827320409770/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon circular size='big' color='blue' name='facebook' />
          </a>

          <a
            href='https://www.instagram.com/schoolpasslive/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon circular size='big' color='yellow' name='instagram' />
          </a>
          <a
            href='https://twitter.com/home'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon circular size='big' inverted color='teal' name='twitter' />
          </a>
          <a
            href='https://www.youtube.com/channel/UCMHEIMOiZOyHt8VWwJ52x6g?view_as=subscriber'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon circular size='big' inverted color='red' name='youtube' />
          </a>
          <a
            href='https://www.linkedin.com/in/ganttech/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon circular size='big' inverted color='blue' name='linkedin' />
          </a>
        </div>
      </Segment>
    </Fragment>
  );
};

export default FollowUs;

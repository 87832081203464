import React, { Component, createRef } from 'react';
import { Header, Icon, Divider, Grid } from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import ContactInfo from '../about/contactus/ContactInfo';
import FollowUs from '../about/FollowUs';
import MeetTheTeam from '../about/team/MeetTheTeam';

class AlarmsPage extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={12}>
          <Header size='huge'>
            <Icon name='help circle' />
            Alarms Page
          </Header>
          <Divider />
          <div ref={this.contextRef}>Info Scrolls Here...</div>
        </Grid.Column>
        <Grid.Column width={4}>
          <ContactInfo />
          <FollowUs />
          <MeetTheTeam showImages={true} />
        </Grid.Column>
        <Grid.Column width={10}>
          {/* <Loader active={loading} /> */}
        </Grid.Column>
      </Grid>
    );
  }
}
export default AlarmsPage;

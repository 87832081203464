import React, { Fragment } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import AppStoreLogos from './AppStoreLogos';

const AppStoreLogosSidebar = () => {
  return (
    <Fragment>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        inverted
        color='black'
      >
        <Header>AVAILABLE NOW</Header>
      </Segment>
      <Segment attached>
        <AppStoreLogos />
      </Segment>
    </Fragment>
  );
};

export default AppStoreLogosSidebar;

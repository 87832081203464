import React from 'react';
import {
  Header,
  Item,
  Icon,
  Divider,
  Segment
} from 'semantic-ui-react';
import SloganTeacher from '../slogan/SloganTeacher';
import { Link } from 'react-router-dom';

const Features = () => {
  return (
    <Segment>
      School Pass Live!™ was created by a{' '}
      <Link to='/team/jamie'>high school teacher</Link>. Check out some of the
      features below: <Divider />
      <Header>
        <Icon name='globe' />
        Features
      </Header>
      <SloganTeacher />
      <Divider />
      <Item.Group>
        <Item>
          <Icon name='compass' size='huge' color='teal' />

          <Item.Content>
            <Item.Header as={Link} to={'/passes'}>
              SAFER
            </Item.Header>
            <Item.Meta>Real Time Monitoring</Item.Meta>
            <Item.Description>
              View student location at school only when they request to leave a
              teacher's classroom.
            </Item.Description>
            <Item.Extra>
              In an Emergency, know exactly where students are that are not in
              classrooms.
            </Item.Extra>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Icon name='heart' size='huge' color='teal' />
          <Item.Content>
            <Item.Header as={Link} to={'/passes'}>
              EASIER
            </Item.Header>
            <Item.Meta>
              Increased Instructional Time & Classroom Rules
            </Item.Meta>
            <Item.Description>
              {' '}
              Students fill out their pass and request permission from their
              teacher.
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Icon name='universal access' size='huge' color='teal' />

          <Item.Content>
            <Item.Header as={Link} to={'/passes'}>
              SMARTER
            </Item.Header>
            <Item.Meta>Pass History</Item.Meta>
            <Item.Description>
              View reports of all hall passes at a school & send student
              messages while on a hall pass.
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider />
      </Item.Group>
    </Segment>
  );
};

export default Features;

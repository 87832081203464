import React, { Fragment, Component } from 'react';
import { Segment, Header, Comment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PassDetailedChatForm from './PassDetailedChatForm';
import { formatDistance } from 'date-fns';

class PassDetailChat extends Component {
  render() {
    const { addPassComment, passId, passChat, isPassActive } = this.props;
    return (
      <Fragment>
        <Segment
          textAlign='center'
          attached='top'
          inverted
          color='black'
          style={{ border: 'none' }}
        >
          <Header>Messages</Header>
        </Segment>

        <Segment attached>
          {passChat &&
            passChat.map(comment => (
              <Comment.Group key={comment.id}>
                <Comment>
                  <Comment.Avatar
                    src={comment.photoURL || '/assets/user.png'}
                  />
                  <Comment.Content>
                    <Comment.Author as={Link} to={`/profile/${comment.uid}`}>
                      {comment.displayName}
                    </Comment.Author>
                    <Comment.Metadata>
                      <div>{formatDistance(comment.date, Date.now())} ago</div>
                    </Comment.Metadata>
                    <Comment.Text>{comment.text}</Comment.Text>
                  </Comment.Content>
                </Comment>
              </Comment.Group>
            ))}
          <PassDetailedChatForm
            addPassComment={addPassComment}
            passId={passId}
            form={'newComment'}
            isPassActive={isPassActive}
          />
        </Segment>
      </Fragment>
    );
  }
}

export default PassDetailChat;

import React, { Fragment } from 'react';
import {
  Segment,
  Image,
  Item,
  Header,
  Button,
  Label,
  Icon
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

const eventImageStyle = {
  filter: 'brightness(80%)'
};

const eventImageTextStyle = {
  position: 'absolute',
  bottom: '5%',
  left: '5%',
  width: '100%',
  height: 'auto',
  color: 'white'
};

const PassDetailHeader = ({
  event,
  isMyPass,
  isTeacher2Approve,
  goingtoEvent,
  cancelGoingToEvent
}) => {
  const endTime = event.passEnd && format(event.passStart.toDate(), 'h:mm a');
  return (
    <Segment.Group>
      <Segment basic attached='top' style={{ padding: '0' }}>
        <Image
          src={
            event && event.category !== ''
              ? `/assets/categoryImages/${event.category}Chalkboard.jpg`
              : `/assets/categoryImages/classroomChalkboard.jpg`
          }
          fluid
          style={eventImageStyle}
        />

        <Segment basic style={eventImageTextStyle}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Header
                  size='huge'
                  content={event.studentName}
                  style={{ color: 'yellow' }}
                />
                <p>
                  {event.passStart && (
                    <Icon name='clock' size='large' inverted color='black' />
                  )}
                  {event.passStart &&
                    format(event.passStart.toDate(), 'h:mm a')}
                  {event.passEnd && (
                    <Icon name='calendar' size='large' inverted color='black' />
                  )}
                  {endTime ? ` - ${endTime} ` : ''}
                </p>
                <p>
                  <Icon
                    size='large'
                    inverted
                    color='black'
                    name='building outline'
                  />
                  From:{' '}
                  <strong>
                    <Link to={`/passes/${event.id}`} style={{ color: 'white' }}>
                      {event.departFrom}
                    </Link>
                  </strong>{' '}
                  to{' '}
                  <strong>
                    <Link to={`/passes/${event.id}`} style={{ color: 'white' }}>
                      {event.destination}
                    </Link>{' '}
                    {event.destionationTeacher}
                  </strong>
                </p>
                <p>
                  {event.comments && (
                    <Icon size='large' inverted color='black' name='info' />
                  )}
                  {event.comments}
                </p>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment>

      <Segment attached='bottom' clearing>
        {/* Awaiting Approval */}
        {!event.isApproved && (
          <Label
            style={{ top: '-90px' }}
            ribbon='right'
            color='red'
            content='Awaiting Teacher Approval'
          />
        )}


        {event.passStart && <Fragment>
          <Icon name='calendar' size='large' color='teal' />
          {format(event.passStart.toDate(), 'EEEE LLLL do, yyyy')}
        </Fragment>}
          

        {/* ON PASS */}
        {event.isApproved && (
          <span>
            <Icon name='calendar' size='large' color='teal' />
            {format(event.passStart.toDate(), 'EEE LLL do yyyy')}{' '}
            <Icon name='clock' color='teal' size='large' /> Return:{' '}
            {format(event.passStart.toDate(), 'h:mm a')}
          </span>
        )}

        {isMyPass && (
          <Button
            as={Link}
            to={`/manage/${event.id}`}
            floated='right'
            color='teal'
          >
            Cancel Pass
          </Button>
        )}
      </Segment>
    </Segment.Group>
  );
};

export default PassDetailHeader;

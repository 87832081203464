import React from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';
// import differenceInYears from 'date-fns/differenceInYears';
import LazyLoad from 'react-lazyload';
const UserDetailedHeader = ({ profile }) => {
  //   let age;
  //   if (profile.dateOfBirth) {
  //     age = differenceInYears(Date.now(), profile.dateOfBirth.toDate());
  //   } else {
  //     age = 'unknown age';
  //   }
  let bgImage;
  if (profile.accountType && profile.accountType === 'Student') {
    bgImage = 'http://schoolpasslive.com/images/chalkboardBlackStudent.jpg'; // Add Student
  } else if (profile.accountType && profile.accountType === 'Educator') {
    bgImage = 'http://schoolpasslive.com/images/chalkboardBlackEducator.jpg'; // Add Student
  } else {
    bgImage = 'http://schoolpasslive.com/images/chalkboardBlack.jpg'; // Add Educator
  }
  return (
    <Segment.Group>
      <Segment
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${bgImage})`,
          padding: '30px'
        }}
      >
        <Item.Group>
          <Item>
            <LazyLoad
              height={150}
              placeholder={
                <Item.Image size='small' bordered src='/assets/user.png' />
              }
            >
              <Item.Image
                avatar
                size='small'
                bordered
                src={profile.photoURL || '/assets/user.png'}
              />
            </LazyLoad>

            <Item.Content verticalAlign='bottom'>
              <Header as='h1' inverted color='teal'>
                {profile.displayName}
              </Header>
              <br />
              <Header as='h3' inverted color='yellow'>
                {profile.position}
              </Header>
              <br />
              <Header as='h3' inverted color='grey'>
                {/* {age}, */}
                Lives in {profile.city || 'unkown city'}
              </Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment
        textAlign='center'
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
        }}
      />
    </Segment.Group>
  );
};

export default UserDetailedHeader;

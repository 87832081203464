import React from 'react';
import {
  Grid,
  Header,
  Segment
  //   Icon,
  //   item,
  //   list,
  //   List,
  //   Item
} from 'semantic-ui-react';
import format from 'date-fns/format';

const UserDetailedDescription = ({ profile }) => {
  let createdAt, firstName;
  if (profile.createdAt) {
    createdAt = format(profile.createdAt.toDate(), 'd MMM yyyy');
  }
  if (String(profile.displayName).split(' ').length > 0) {
    firstName = String(profile.displayName).split(' ')[0];
  } else {
    firstName = profile.displayName;
  }
  return (
    <Segment>
      <Grid columns={2}>
        <Header icon='smile' color='teal' content={`About ${firstName}`} />
        <p>
          I am a <strong>{profile.accountType || 'Student'} </strong>
        </p>
        <p>
          School ID#: <strong>{profile.schoolID || '???'}</strong>
        </p>
        <p>
          Joined: <strong>{createdAt}</strong>
        </p>
        <p>{profile.description}</p>
      </Grid>
    </Segment>
  );
};

export default UserDetailedDescription;

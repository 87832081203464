import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

const SocialLogin = ({ socialLogin, microsoftLogin }) => {
  return (
    <div>
      <Button
        onClick={() => microsoftLogin('microsoft')}
        type='button'
        fluid
        color='blue'
      >
        <Icon name='microsoft' />
        Microsoft School Account
      </Button>
      <br />
      <Button
        onClick={() => socialLogin('google')}
        type='button'
        fluid
        color='google plus'
      >
        <Icon name='google plus' />
        Login with Google
      </Button>
      <br />
      <Button
        onClick={() => socialLogin('facebook')}
        type='button'
        style={{ marginBottom: '10px' }}
        fluid
        color='facebook'
      >
        <Icon name='facebook' /> Login with Facebook
      </Button>
    </div>
  );
};

export default SocialLogin;

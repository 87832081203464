import React, { Component } from 'react';
import { Segment, Item, Icon, Button, Image, Label } from 'semantic-ui-react';
// import EventListAtendee from './EventListAtendee';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

class PassRequestListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { showMap: false };
  }

  handleToggleMap = () => {
    this.setState({
      showMap: !this.state.showMap
    });
  };

  render() {
    const { event, deletePassRequest, showPassRequestButton } = this.props;
    const endTime = event.passEnd && format(event.passStart.toDate(), 'h:mm a');

    return (
      <Segment.Group>
        <Segment
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardLong.jpg'})`,
            padding: '30px'
          }}
        >
          <Item.Group>
            <Item>
              <Item.Image
                size='tiny'
                circular
                src={`/assets/categoryImages/${event.category}.png`}
              />
              <Item.Content>
                <Item.Header as={Link} to={`/profile/${event.studentUID}`}>
                  <span style={{ color: 'yellow' }}>{event.studentName}</span>
                </Item.Header>
                <Item.Description>
                  <span style={{ color: 'white' }}>From:</span>{' '}
                  <em>
                    <Link to={`/passesRequests/${event.id}`}>
                      <span style={{ color: 'cyan' }}>{event.departFrom}</span>
                    </Link>
                  </em>
                </Item.Description>
                <Item.Description>
                  <span style={{ color: 'white' }}>To:</span>{' '}
                  <em>
                    <Link to={`/passes/${event.id}`}>
                      <span style={{ color: 'cyan' }}>
                        {' '}
                        {event.destination}
                      </span>
                    </Link>
                  </em>
                </Item.Description>
                <Item.Description>
                  <span>
                    <Icon name='clock' inverted />
                    <span style={{ color: 'cyan' }}>
                      {format(event.passStart.toDate(), 'h:mm a')}{' '}
                      {endTime ? `- ${endTime} ` : ''} |{' '}
                      <Icon name='calendar alternate outline' inverted />
                      {format(event.passStart.toDate(), 'EEE LLL do yyyy')}
                    </span>
                  </span>
                </Item.Description>
              </Item.Content>
              <Image
                as={Link}
                to={`/profile/${event.studentUID}`}
                size='tiny'
                circular
                src={event.studentPhotoURL}
              />
            </Item>
          </Item.Group>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
        <Segment clearing>
          <Label
            style={{ top: '-80px' }}
            ribbon='right'
            color='red'
            content='Awaiting Teacher Approval'
          />{' '}
          <span>
            Comments/Reason:{' '}
            {event && event.comments && event.comments !== ''
              ? event.comments
              : 'None'}
          </span>
          {showPassRequestButton && (
            <Button
              onClick={() => deletePassRequest(event.id, 'passesRequests')}
              as='a'
              color='teal'
              floated='right'
              content='Cancel Pass Request'
            />
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

export default PassRequestListItem;

import React from 'react';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Image,
  Icon,
  List
} from 'semantic-ui-react';
import ClassPass from './appStore/ClassPass';
import FollowUs from './FollowUs';
import Footer from '../nav/NavBar/Footer';
import SchoolInfo from '../school/schoolInfo/SchoolInfo';

const ClassPassPage = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='share square outline' color='red' />
          Class Pass
        </Header>
        <Divider />
        <Segment>
          <ClassPass />
          <Header>Using Class Pass Mode</Header>
          <p>
            To use one mobile device as a shared pass in a classroom, a teacher
            needs to turn on class Pass mode. Follow the steps below to turn on
            this mode.
          </p>
          <p>
            In Class Pass Mode, students only enter their name and where they
            are going on the classroom shared mobile device. Real Time
            monitoring is known while they are out of the classroom.
          </p>
          <p>
            <span className='chalkboardPink'>NOTE: </span>Is is{' '}
            <strong>strongly suggested</strong> to get a{' '}
            <em>WATER PROOF CASE</em> if you choose to use a shared mobile
            device to prevent damage to that device.
          </p>

          <Grid columns='equal'>
            <Grid.Column>
              <strong>Turn On Class Pass Mode</strong>
              <List bulleted>
                <List.Item>
                  On your Mobile Device you want to share, login with your
                  teacher account
                </List.Item>
                <List.Item>Go to your Classroom Rules</List.Item>
                <List.Item>Turn Class Pass Mode On</List.Item>
              </List>
              <Image centered src='/assets/phonePreview/classRulesL.png' />
            </Grid.Column>
            <Grid.Column>
              <strong>Turn Off Class Pass Mode</strong>
              <List bulleted>
                <List.Item>
                  On your Mobile Device that class pass is setup on
                </List.Item>
                <List.Item>Open the Menu</List.Item>
                <List.Item>
                  Under Educator, Click Unlock Class Pass & Enter your Educator
                  Password
                </List.Item>
              </List>
              <Image centered src='/assets/phonePreview/menuClassPassL.png' />
            </Grid.Column>
          </Grid>
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}>
        <Image src='/assets/phonePreview/classPassXL.png' />
        <SchoolInfo />
        <FollowUs />
      </Grid.Column>
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default ClassPassPage;

import React from 'react';
import { Container, Icon, List, Button, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const AppStoreDescription = () => {
  return (
    <Container>
      <p>
        School Pass Live!™ is available for both{' '}
        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          iOS
          <Icon size='large' name='apple' color='teal' />
        </a>
        and{' '}
        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          Android <Icon size='large' name='android' color='olive' />
        </a>
        via the{' '}
        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          App Store
        </a>
        and{' '}
        <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
          Google Play Store
        </a>
      </p>
      <p>
        School Pass Live!™ was created by a High School Teacher after having
        friends effected by the Marjory Stoneman Douglas Shooting.
      </p>
      <p>
        School Pass Live!'s revolutionary design & utility is transforming
        education and designed to provide schools with increased
        accountability, security and additional data not available with
        traditional written hall passes.
      </p>
      <p>
        School Pass Live!™ is used by teachers & students to replace
        traditional paper hall passes. It allows students to use their
        cellphone or a teacher's shared device in{' '}
        <Link to='/sharedClassPass'>CLASS PASS MODE</Link> as a pass for all
        their classes very easily.{' '}
        <span className='chalkboardOrange'>STUDENT'S WITHOUT PHONES</span>
        <em /> can use this website with Google Chrome{' '}
        <Icon name='chrome' size='large' /> to use School Pass Live! in their
        classrooms & schools.
      </p>
      <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
        <Button icon labelPosition='left' color='red'>
          <Icon name='app store ios' size='large' />
          Get on iOS
        </Button>
      </a>
      <a href='appStoreLink.com' target='_blank' rel='noopener noreferrer'>
        <Button icon labelPosition='left' color='red'>
          <Icon name='google play' size='large' />
          Get on Android
        </Button>
      </a><Divider />
      <Container>
        <strong>* COMMON TRADITIONAL PASS ISSUES</strong>
        <List>
          <List.Item>
            1) Loss of Instructional time for Teachers writing a pass{' '}
          </List.Item>
          <List.Item>
            2) Students can wonder wherever with no accountability of time or
            location
          </List.Item>
          <List.Item>
            {' '}
            3) Student repetition of leaving class during certain classes{' '}
          </List.Item>
          <List.Item>
            4) Sanitary: Regular class pass, all students touch same pass
            spreading germs
          </List.Item>
        </List>
      </Container>
      <Divider />
    </Container>
  );
};

export default AppStoreDescription;

import React from 'react';
import {
  Segment,
  Container,
  Header,
  Button,
  Icon,
  Image
} from 'semantic-ui-react';
import HomeSlider from './HomeSlider';

const HomePage = ({ history }) => {
  return (
    <Segment inverted textAlign='center' vertical className='masthead'>
      <Container text>
        {/* <HomeSlider /> */}
        <iframe src='https://www.youtube.com/embed/2S8KFXNerpI'
        frameBorder='0'
        allow='autoplay; encrypted-media'
        allowFullScreen
        title='video'
        width="560"
        height="315"
        />

        <Header as='h1' inverted>
          <Image
            size='massive'
            src='/assets/logo.png'
            alt='logo'
            style={{ marginBottom: 12 }}
          />
          School Pass Live!™
        </Header>
        <h2 className='chalkboardYellowTitle'>
          "Safer, Easier, Smarter, Passes For Classes" ™
        </h2>
        <Button
          onClick={() => history.push('/about/info')}
          size='huge'
          color='red'
        >
          Get started
          <Icon name='right arrow' inverted />
        </Button>
        <h4>Created by a High School Teacher</h4>
      </Container>
    </Segment>
  );
};

export default HomePage;

import React, { Fragment } from 'react';
import { Table, Header, Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Pricing = () => {
  return (
    <Fragment>
      <Header>What you get with School Pass Live!</Header>
      <Image src='/assets/shop/pennyAPass.png' floated='left' /> As a teacher,
      I know schools & teachers's don't have excess money to spend. Each{' '}
      <em>School & Teacher</em> is given{' '}
      <strong>FREE passes to use each school year</strong>. Each pass in our
      system is a <em>commodity</em> (just like a traditional paper pass,
      there is a cost to each pass). Once all FREE passes have been used,
      additional passes can be purchased for about a{' '}
      <span className='chalkboardOrangeTitle'>Penny-A-Pass</span> <br />
      (example: <em>500 passes/$4.99</em>). Contact us for information on
      purchasing an <em>A+ Account</em> to get unlimited passes at your school
      or district.
      <Table definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Free account</Table.HeaderCell>
            <Table.HeaderCell>"A+" Account</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>
              School (<em>Per School Year</em>)
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Header>200</Header>school shared passes
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Header>Unlimited</Header>school shared passes
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Classroom (<em>Per School Year</em>)
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Header>200</Header> classroom only passes
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Header>Unlimited</Header> classroom only passes
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell />
            <Table.Cell textAlign='center'>
              <Button
                as={Link}
                to={'/signup'}
                color='red'
                content='Sign Up'
              />
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Button
                as={Link}
                to={'/contact/form'}
                color='red'
                content='Contact Us'
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Fragment>
  );
};

export default Pricing;

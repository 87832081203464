import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';

const TraditionalPass = () => {
  return (
    <Segment.Group>
      <Segment
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${chalkboard})`,
          padding: '30px'
        }}
      >
        {' '}
        <Header inverted color='yellow'>
          TRADITIONAL SCHOOL HALL PASSES SCENARIO
        </Header>
        <p>
          <Image
            floated='left'
            src='/assets/phonePreview/requestPermission.png'
          />
          Teachers are providing instruction when one student asks to use the
          restroom or go to the office. The teacher stops instruction and has
          to write a pass for the student to leave. The teacher goes back to
          teaching but there is no way to monitor how long the student is out
          of the classroom or if they go to the restroom on the first floor vs
          the 3rd floor.
        </p>{' '}
        <p>
          <span className='chalkboardGreen'>
            There is now NO need to interrupt instruction to write student
            pass information anymore. Also you can monitor were the student
            goes.
          </span>{' '}
        </p>{' '}
        <p>
          Students complete required pass information in the School Pass Live!
          on their own mobile device then send the request to ask for
          permission to leave the classroom. The teacher approves or denies
          it. It's that easy.
        </p>
      </Segment>
      <Segment
        textAlign='center'
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
        }}
      />
    </Segment.Group>
  );
};

export default TraditionalPass;

import React, { Component, Fragment } from 'react';
import PassListItem from './PassListItem';
import InfiniteScroll from 'react-infinite-scroller';

class PassList extends Component {
  render() {
    const {
      passes,
      selectEvent,
      getNextPasses,
      loading,
      morePasses
      // deleteEvent
    } = this.props;
    return (
      <Fragment>
        {passes && passes.length !== 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={getNextPasses}
            hasMore={!loading && morePasses}
            initialLoad={false}
          >
            {passes &&
              passes.map(event => (
                <PassListItem
                  key={event.id}
                  event={event}
                  selectEvent={selectEvent}
                  // deleteEvent={deleteEvent}
                />
              ))}
          </InfiniteScroll>
        )}
      </Fragment>
    );
  }
}

export default PassList;

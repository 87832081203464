import React, { Fragment } from 'react';
import { Grid, Item, Icon, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SloganSchool from '../slogan/SloganSchool';

const FaqGrid = () => {
  return (
    <Fragment>
      <SloganSchool />
      <Divider />
      <Grid columns={3} divided>
        <Grid.Column>
          <Item.Group>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='settings' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>How Does it Work?</Item.Header>
                <Item.Meta>How do I set it up at my school/classroom</Item.Meta>
              </Item.Content>
            </Item>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='address book' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>Student Pass Archives?</Item.Header>
                <Item.Meta>
                  How to view student Pass records & history
                </Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
        <Grid.Column>
          <Item.Group>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='map marker alternate' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>Real Time Monitoring?</Item.Header>
                <Item.Meta> When does it monitor students at school</Item.Meta>
              </Item.Content>
            </Item>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='tasks' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>Classroom Pass Rules</Item.Header>
                <Item.Meta>How to Set up Class pass rules & limits</Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
        <Grid.Column>
          <Item.Group>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='globe' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>Use Without Mobile Devices</Item.Header>
                <Item.Meta>How to use without mobile devices</Item.Meta>
              </Item.Content>
            </Item>
            <Item as={Link} to={'/info/faq'}>
              <Icon name='share square outline' size='huge' color='teal' />
              <Item.Content>
                <Item.Header>Shared Device Class Pass</Item.Header>
                <Item.Meta>Using 1 device as a shared class pass</Item.Meta>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default FaqGrid;

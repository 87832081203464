import React, { Fragment } from 'react';
import { Segment, Header, List } from 'semantic-ui-react';

const AppStoreFeatureList = () => {
  return (
    <Fragment>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        inverted
      >
        <Header>MORE FEATURES</Header>
      </Segment>
      <Segment attached>
        Every account is <span className='chalkboardOrange'>FREE</span> and includes the following:
        <List bulleted>
          <List.Item>Manage Students leaving classroom</List.Item>
          <List.Item>Track your classes </List.Item>
          <List.Item>Student location on the school site </List.Item>
          <List.Item>Time while out of class</List.Item>
          <List.Item>Student Pass History </List.Item>
          <List.Item>Teacher Room Pass History </List.Item>
          <List.Item>
            Security Guard monitor locations of students and time they have been
            out of the classroom{' '}
          </List.Item>
          <List.Item>Save money on printing </List.Item>
          <List.Item>Ensure pass validity</List.Item>
          <List.Item>Monitor student movement in the building </List.Item>
          <List.Item>Improve instruction</List.Item>
          <List.Item>Identify pass violators </List.Item>
          <List.Item>
            Identify abuse of school hall pass rules - Increase school security
            measures{' '}
          </List.Item>
          <List.Item>
            Collect data previously unavailable with traditional passes
          </List.Item>
        </List>
      </Segment>
    </Fragment>
  );
};

export default AppStoreFeatureList;

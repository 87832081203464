import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const SloganTeacher = () => {
  return (
    <Container textAlign='center'>
      <Header textAlign='center' color='red'>
        "SAFER, EASIER, SMARTER, PASSES FOR CLASSES" ™
      </Header>
      An easier way to manage hall passes and make more time for teaching.
    </Container>
  );
};

export default SloganTeacher;

import React, { Component, createRef } from 'react';
import { Header, Icon, Divider, Grid, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import LoadingComponent from '../../app/layout/LoadingComponent';
import FeaturesList from './appFeatures/FeaturesList';
import FollowUs from './FollowUs';
import AppStoreFeatureList from './appStore/AppStoreFeatureList';
import Footer from '../nav/NavBar/Footer';
import HomeSlider from '../home/HomeSlider';
import ContactInfo from './contactus/ContactInfo';
import { openModal } from '../modals/modalActions';

const mapState = state => ({
  isLoggedIn: !state.firebase.auth.isEmpty
});

const actions = {
  openModal
};

class AboutInfo extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true
  };

  handleSignin = () => {
    this.props.openModal('LoginModal');
  };
  handleRegister = () => {
    this.props.openModal('RegisterModal');
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    // const { isLoggedIn } = this.props;
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))

    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='smile outline' color='red' />
            Welcome to School Pass Live!
          </Header>
          <Divider />
          <div ref={this.contextRef}>
            <HomeSlider />

            {/* {!isLoggedIn && (
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button
                      onClick={this.handleRegister}
                      color='teal'
                      fluid
                      content='Register'
                      style={{ marginLeft: "0.5em" }}
                    />
                  </Grid.Column>{" "}
                  <Grid.Column>
                    <Button
                      onClick={this.handleSignin}
                      color='red'
                      content='Login'
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )} */}
            <FeaturesList />
            <AppStoreFeatureList />
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <Image src='/assets/phonePreview/requestPermissionXL.png' />
          <br />
          <FollowUs />
          <ContactInfo />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}
export default connect(
  mapState,
  actions
)(AboutInfo);

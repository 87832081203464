import React, { Component, Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import UserDetailedHeader from './UserDetailedHeader';
import UserDetailedDescription from './UserDetailedDescription';
import UserDetailedPhotos from './UserDetailedPhotos';
import UserDetailedSidebar from './UserDetailedSidebar';
import UserDetailedEvents from './UserDetailedEvents';
import { userDetailedQuery } from '../userQueries';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { getUserPasses } from '../userActions';
import Footer from '../../nav/NavBar/Footer';

const mapState = (state, ownProps) => {
  let uid = null;
  let profile = {};

  if (ownProps.match.params.id === state.auth.uid) {
    profile = state.firebase.profile;
  } else {
    profile =
      !isEmpty(state.firestore.ordered.profile) &&
      state.firestore.ordered.profile[0];
    uid = ownProps.match.params.id;
  }

  return {
    profile,
    uid,
    activeIndex: state.activeIndex,
    passes: state.passes.userPasses,
    passesLoading: state.async.loading,
    auth: state.firebase.auth,
    photos: state.firestore.ordered.photos,
    requesting: state.firestore.status.requesting
  };
};

const actions = {
  getUserPasses
};

class UserDetailedPage extends Component {
  state = {
    morePasses: false,
    loadingInitial: true,
    loadedPasses: []
  };

  async componentDidMount() {
    await this.props.getUserPasses(this.props.uid);
  }

  changeTab = (event, data) => {
    this.props.getUserPasses(this.props.uid, data.activeIndex);
  };

  render() {
    const {
      profile,
      photos,
      auth,
      match,
      requesting,
      passes,
      passesLoading
    } = this.props;
    const isCurrentUser = auth.uid === match.params.id;

    // If any requesting components is set to true, then loading my profile
    const loading = Object.values(requesting).some(a => a === true);
    if (loading) return <LoadingComponent />;

    return (
      <Fragment>
        <Grid>
          <Grid.Column width={10}>
            <UserDetailedHeader profile={profile} />
            <UserDetailedDescription profile={profile} />
            {photos && photos.length > 1 && (
              <UserDetailedPhotos photos={photos} />
            )}
            <UserDetailedEvents
              passes={passes}
              passesLoading={passesLoading}
              changeTab={this.changeTab}
              loading={loading}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <UserDetailedSidebar isCurrentUser={isCurrentUser} />
          </Grid.Column>
        </Grid>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Fragment>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  firestoreConnect((auth, uid) => userDetailedQuery(auth, uid))
)(UserDetailedPage);

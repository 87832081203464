import React from 'react';
import { Grid, Header, Icon, Divider, Segment, Image } from 'semantic-ui-react';
import TraditionalPass from '../appStore/TraditionalPass';
import AppStoreFeatureList from '../appStore/AppStoreFeatureList';
import ContactInfo from '../contactus/ContactInfo';
import FaqGrid from '../faq/FaqGrid';
import Footer from '../../nav/NavBar/Footer';

const SchoolsInfoVisitor = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='graduation cap' color='red' />
          Schools
        </Header>
        <Divider />
        <Segment>
          <Image src='/assets/carousel/passHisotry.jpg' />
          <TraditionalPass />
          <FaqGrid />
        </Segment>
        <AppStoreFeatureList />
      </Grid.Column>
      <Grid.Column width={6}>
        <Image src='/assets/phonePreview/viewStudentsXL.png' />
        <ContactInfo />
      </Grid.Column>{' '}
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default SchoolsInfoVisitor;

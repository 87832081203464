import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PassRequestListItem from './PassRequestListItem';
import { connect } from 'react-redux';

const mapState = state => ({
  // CHANGE TO DIRECT FIRESTORE vs REDUX: https://www.udemy.com/build-an-app-with-react-redux-and-firestore-from-scratch/learn/lecture/10200830#content
  passes: state.passes.passes, // redux firestore state.firestore.ordered.passes vs. direct from firestore
  myUid: state.firebase.auth.uid
});

class PassRequestList extends Component {
  render() {
    const {
      passes,
      selectEvent,
      getNextPasses,
      loading,
      morePasses,
      myUid,
      deletePassRequest
    } = this.props;
    return (
      <Fragment>
        {passes && passes.length !== 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={getNextPasses}
            hasMore={!loading && morePasses}
            initialLoad={false}
          >
            {passes &&
              passes.map(event => (
                <PassRequestListItem
                  key={event.id}
                  event={event}
                  selectEvent={selectEvent}
                  deletePassRequest={deletePassRequest}
                  showPassRequestButton={event.studentUID === myUid}
                />
              ))}
          </InfiniteScroll>
        )}
      </Fragment>
    );
  }
}

export default connect(mapState)(PassRequestList);

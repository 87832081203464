import React, { Fragment } from 'react';
import { Segment, Header, Item, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const MeetTheTeam = ({ showImages }) => {
  return (
    <Fragment>
      <Segment style={{ border: 'none' }} attached='top' inverted>
        <Header>Meet The Team</Header>
      </Segment>
      <Segment attached>
        <Item.Group>
          <Item>
            {showImages && (
              <Item.Image
                avatar
                size='tiny'
                src='/assets/team/familyJamie.jpg'
                as={Link}
                to='/about'
              />
            )}
            <Item.Content>
              <Item.Description>
                Jamie {' - '} <Link to='/team/jamie'>biography</Link>
              </Item.Description>{' '}
              <br />
              <Item.Meta as={Link} to='/about'>
                CEO/High School Teacher
              </Item.Meta>
              {showImages && (
                <Item.Extra>
                  Computer Science & V.R. <br />
                  <strong>Teaching:</strong> 9 years
                </Item.Extra>
              )}
            </Item.Content>
          </Item>
          <Divider />
          <Item>
            {showImages && (
              <Item.Image
                avatar
                size='tiny'
                src='/assets/team/familyKaren.jpg'
                as={Link}
                to='/about'
              />
            )}
            <Item.Content>
              <Item.Description>
                Karen {' - '} <Link to='/team/karen'>biography</Link>
              </Item.Description>
              <br />
              <Item.Meta as={Link} to='/about'>
                Elementary Teacher
              </Item.Meta>
              {showImages && (
                <Item.Extra>
                  SECME National Teacher Of Year <br />
                  <strong>Teaching:</strong> 34 years
                </Item.Extra>
              )}
            </Item.Content>
          </Item>
          <Divider />
          <Item>
            {showImages && (
              <Item.Image
                avatar
                size='tiny'
                src='/assets/team/familyGeno.jpg'
                as={Link}
                to='/about'
              />
            )}
            <Item.Content>
              <Item.Description>
                Geno {' - '} <Link to='/team/geno'>biography</Link>
              </Item.Description>
              <br />
              <Item.Meta as={Link} to='/about'>
                School District
              </Item.Meta>
              {showImages && (
                <Item.Extra>
                  Training New Teachers & Support <br />
                  <strong>Teaching:</strong> 14 years
                </Item.Extra>
              )}
            </Item.Content>
          </Item>
          <Divider />
          <Item>
            {showImages && (
              <Item.Image
                avatar
                size='tiny'
                src='/assets/team/familyLisha.jpg'
                as={Link}
                to='/about'
              />
            )}
            <Item.Content>
              <Item.Description>
                Alicia {' - '} <Link to='/team/alicia'>biography</Link>
              </Item.Description>
              <br />
              <Item.Meta as={Link} to='/about'>
                High School Teacher
              </Item.Meta>
              {showImages && (
                <Item.Extra>
                  Trainer, Activities Director, S.T.E.A.M. <br />
                  <strong>Teaching:</strong> 12 years
                </Item.Extra>
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </Fragment>
  );
};

export default MeetTheTeam;

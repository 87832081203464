import React, { Component } from 'react';
import { Form, Button, Divider, Segment, Image } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from '../../../app/common/form/TextArea';
import { combineValidators, isRequired } from 'revalidate';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';

const validate = combineValidators({
  comment: isRequired('comment')
});

class FeedbackForm extends Component {
  onFormSubmit = values => {
    const { addComment, reset } = this.props;
    let path = '/comments/feedback';
    addComment(path, values);
    reset();
  };

  render() {
    const { pristine, submitting, profile, handleSubmit } = this.props;
    return (
      <Segment.Group>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${chalkboard})`,
            padding: '30px'
          }}
        >
          <Image
            bordered
            circular
            size='small'
            centered
            src='/assets/team/askJamieClassroom.jpg'
          />{' '}
          Submit your feedback & suggestions to our team of teachers. <br />{' '}
          Together, we can help spread the word and also make{' '}
          <em>School Pass Live!</em> the best for all of our students. <br />
          <span style={{ color: 'yellow' }}>
            We will get back to you soon, right after we are done grading.
          </span>
          <Divider />
          <p>
            {' '}
            <span style={{ color: 'cyan' }}>
              <strong>{profile.displayName}</strong>
            </span>{' '}
            <br />{' '}
            <span style={{ color: 'pink' }}>
              <em>{profile.schoolName}</em>
            </span>{' '}
          </p>
          <Divider />
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Field name='comment' type='text' component={TextArea} rows={2} />
            <Button
              disabled={submitting || pristine}
              content='Send To Teacher'
              labelPosition='left'
              color='red'
              icon='edit'
            />
          </Form>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
      </Segment.Group>
    );
  }
}

export default reduxForm({
  form: 'feedback',
  destroyOnUnmount: true,
  validate
})(FeedbackForm);

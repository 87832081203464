import React, { Component } from 'react';
import { connect } from 'react-redux';
import { incrementAsync, decrementAsync } from './testActions';
import { Button, Divider } from 'semantic-ui-react';
import TestPlaceInput from './TestPlaceInput';
import SimpleMap from './SimpleMap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { openModal } from '../modals/modalActions';
import NewUserRegistration from '../auth/Register/NewUserRegistration';
import SchoolInfo from '../school/schoolInfo/SchoolInfo';

const mapStateToProps = state => ({
  data: state.test.data,
  loading: state.async.loading,
  buttonName: state.async.elementName
});

const mapActions = {
  incrementAsync,
  decrementAsync,
  openModal
};

class TestComponent extends Component {
  state = {
    latlng: {
      lat: 59.95,
      lng: 30.33
    }
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({
          latlng: latLng
        });
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    const {
      incrementAsync,
      decrementAsync,
      openModal,
      loading,
      buttonName
    } = this.props;
    return (
      <div>
        <SchoolInfo />
        <Divider />
        <NewUserRegistration />
        <Divider />
        {/* <HomeSlider /> */}
        <Divider />
        <h1>Test Component</h1>
        <h3>The answer is {this.props.data}</h3>
        <Button
          onClick={e => incrementAsync(e.target.name)}
          positive
          content='increment'
          loading={buttonName === 'increment' && loading}
        />
        <Button
          name='decrement'
          onClick={e => decrementAsync(e.target.name)}
          content='Decrement'
          loading={buttonName === 'decrement' && loading}
        />
        <Button
          onClick={() => openModal('TestModal', { data: 42 })}
          color='teal'
          content='Open Modal'
        />
        <br />
        <br />
        <br />
        <TestPlaceInput selectAddress={this.handleSelect} />
        <br />
        <br />
        <SimpleMap key={this.state.latlng.lng} latlng={this.state.latlng} />
        {/* // TODO - Change to get from event
    const passRoute =
      // [
      {
        lat: 25.852559,
        lng: -80.372396
      };
    // ,
    // {
    //   lat: 25.852687,
    //   lng: -80.371362
    // },
    // {
    //   lat: 25.852841,
    //   lng: -80.372069
    // },
    // {
    //   lat: 25.852901,
    //   lng: -80.371670
    // }
    // ] */}
        {/* {passRoute &&
          passRoute.map(
            coord => console.log(coord)
            // <StudentOnPass
            //   key={`${location.lat}-${location.lng}`}
            //   lat={location.lat || 25.852687}
            //   lng={location.lng || -80.371362}
            //   text={markerName || ''}
            // />
          )}
        )} */}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapActions
)(TestComponent);

// /*global google*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Form,
  Button,
  Grid,
  Header,
  Icon,
  Divider,
  Image
} from 'semantic-ui-react';
import {
  createPassRequest,
  updateEvent,
  getSchoolCoords,
  cancelToggle,
  getClassroomCoords
} from '../eventActions';
import { reduxForm, Field, formValueSelector } from 'redux-form';
// import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import {
  // composeValidators,
  combineValidators,
  isRequired
  // hasLengthGreaterThan
} from 'revalidate';
import { withFirestore } from 'react-redux-firebase';
import SchoolInfo from '../../school/schoolInfo/SchoolInfo';
import Footer from '../../nav/NavBar/Footer';
import PassDetailedMap from '../PassDetailed/PassDetailedMap';
// import { toastr } from 'react-redux-toastr';
// import PlaceInput from '../../../app/common/form/PlaceInput';
// import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const mapState = (state, ownProps) => {
  const eventId = ownProps.match.params.id; // Get the ID from the URL

  let event = {};
  const selector = formValueSelector('eventForm');
  let selectedDestination = selector(state, 'destination');
  let needComments =
    selectedDestination && selector(state, 'destination') !== 'Restroom';

  // Make sure got events
  let stateEvents = state.firestore.ordered.passes;
  if (stateEvents && eventId && stateEvents.length > 0) {
    event = stateEvents.filter(event => event.id === eventId)[0] || {};
  }

  return {
    initialValues: event,
    event,
    needComments: needComments
  };

  // Map isDestinationAClassroom
};

const actions = {
  createPassRequest,
  getSchoolCoords,
  updateEvent,
  cancelToggle
};

const validate = combineValidators({
  departFrom: isRequired({ message: 'Current Classroom is required' }),
  destination: isRequired('destination')
});

const destinations = [
  { key: 'restroom', text: 'Restroom', value: 'Restroom' },
  { key: 'auditorium', text: 'Auditorium', value: 'Auditorium' },
  { key: 'cafeteria', text: 'Cafeteria', value: 'Cafeteria' },
  { key: 'classroom', text: 'Classroom', value: 'Classroom' },
  { key: 'clinic', text: 'Clinic', value: 'Clinic' },
  { key: 'counselor', text: 'Counselor', value: 'Counselor' },
  { key: 'gymnasium', text: 'Gymnasium', value: 'Gymnasium' },
  { key: 'library', text: 'Library', value: 'Library' },
  { key: 'office', text: 'Main Office', value: 'Office' }
];

const classrooms = [{ key: '102', text: '102', value: '102' }];

class EventForm extends Component {
  state = {
    passRoute: {},
    departLatLng: {},
    departTeacher: '',
    departTeacherUid: '',
    destinationLatLng: {},
    destinationTeacher: '',
    destinationTeacherUid: '',
    startTime: ''
  };

  state = {
    loadingInitial: true,
    latlng: {
      lat: 25.852687,
      lng: -80.371362
    }
  };

  async componentDidMount() {
    const { firestore, match } = this.props;

    // Listen to Data (CHANGE THIS TO FIRESTORE CONNECT)
    await firestore.setListener(`passes/${match.params.id}`);
  }

  async componentWillUnmount() {
    const { firestore, match } = this.props;

    // Listen to Data (CHANGE THIS TO FIRESTORE CONNECT)
    await firestore.unsetListener(`passes/${match.params.id}`);
  }

  // CLASS METHODS
  onFormSubmit = async values => {
    // Add Values for LatLng when create Event
    values.isTrackingPass = false;
    values.passRoute = this.state.passRoute || [];
    values.departLatLng = this.state.departLatLng || {
      lat: 25.852687,
      lng: -80.371362
    };
    values.departTeacher = this.state.departTeacher || 'Mickey Mouse';
    values.departTeacherUid = this.state.departTeacherUid || '';
    values.destinationLatLng = this.state.destinationLatLng || {
      lat: 25.852687,
      lng: -80.371362
    };
    values.category = this.props.isDestinationClassroom
      ? 'classroom'
      : values.destination.toLowerCase();

    // Add Destination Teacher if Classroom
    // TODO: IF DON"T SAVE THIS, CAN APPS STILL LOAD FORM FIREBASE
    if (this.props.isDestinationClassroom) {
      values.destinationTeacher = this.state.destinationTeacher;
      values.destinationTeacherUid = this.state.destinationTeacherUid;
    }

    try {
      // TODO Check AUto Approve on or Send Pass Request

      //If Have an ID from selected Event, Update the Event
      // if (this.props.initialValues.id) {
      //   // Get Values Back since listening to Event
      //   if (Object.keys(values.passRoute.length === 0)) {
      //     values.passRoute = this.props.event.passRoute;
      //   }
      //   if (Object.keys(values.departTeacher.length === 0)) {
      //     values.departTeacher = this.props.event.departTeacher;
      //   }
      //   if (Object.keys(values.departTeacherUid.length === 0)) {
      //     values.departTeacherUid = this.props.event.departTeacherUid;
      //   }
      //   if (Object.keys(values.departLatLng.length === 0)) {
      //     values.departLatLng = this.props.event.departLatLng;
      //   }
      //   if (Object.keys(values.destinationLatLng.length === 0)) {
      //     values.destinationLatLng = this.props.event.destinationLatLng;
      //   }
      //   if (Object.keys(values.destinationTeacher.length === 0)) {
      //     values.destinationTeacher = this.props.event.destinationTeacher;
      //   }
      //   if (Object.keys(values.destinationTeacherUid.length === 0)) {
      //     values.destinationTeacherUid = this.props.event.destinationTeacherUid;
      //   }

      //   this.props.updateEvent(values);
      //   // this.props.history.push(`/passes/${this.props.initialValues.id}`);

      //   this.props.history.push(`/passesRequests/`);
      // } else {
      // Go to That Pass Information
      // let createdEvent = await this.props.createPassRequest(values);
      // this.props.history.push(`/passesRequests/${createdEvent.id}`);

      this.props.history.push('/passesRequests/');
      // }
    } catch (error) {
      console.log(error);
    }
  };

  handleClassroomSelect = currentClassroom => {
    // TODO LOOKUP CLASSROOM INFO
    let teacher = '';
    let uid = '';
    // let coordinates = new google.maps.LatLng(this.state.departFromCoords);
    // let coordinates = new google.maps.LatLng(30.1191065, 30.1191065);

    // Update State
    this.setState({
      // Lookup Current Teacher Name and UID
      departFromTeacher: teacher,
      departFromTeacherUId: uid,
      departFromCoords: getClassroomCoords(currentClassroom)
    });
    // .then(() => {
    // console.log(this.state.departFromCoords);
    // })

    // Show on Map
  };

  handleDestinationSelect = destination => {
    // TODO: LOOKUP CLASSROOM INFO
    let teacher = '';
    let uid = '';
    // let coordinates = { latitude: 30.1191065, longitude: 30.1191065 };

    // SET STATE
    this.setState({
      destinationTeacher: teacher,
      destinationTeacherUId: uid,
      destinationTeacherCoords: getClassroomCoords(destination)
    });

    console.log(this.state.destinationCoords);
  };

  render() {
    const {
      history,
      initialValues,
      needComments,
      invalid,
      submitting,
      pristine,
      loading
    } = this.props;
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='graduation cap' color='red' />
            Pass Request
          </Header>
          <Divider />
          <PassDetailedMap
            key={this.state.latlng.lng}
            latlng={this.state.latlng}
            showSatellite={true}
            zoomLevel={17}
          />
          <Segment.Group>
            <Segment
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardLong.jpg'})`,
                padding: '30px',
                height: '100%'
              }}
            >
              <Form
                onSubmit={this.props.handleSubmit(this.onFormSubmit)}
                autoComplete='off'
              >
                <Header inverted color='yellow' content='CURRENT CLASSROOM' />
                <Field
                  name='departFrom'
                  component={SelectInput}
                  placeholder='What Classroom are you in?'
                  options={classrooms}
                  onSelect={this.handleClassroomSelect}
                />
                <Header inverted color='yellow' content='DESTINATION' />
                <Field
                  name='destination'
                  component={SelectInput}
                  onSelect={this.handleDestinationSelect}
                  options={destinations}
                  placeholder='Where Are you Going?'
                />
                {needComments && (
                  <Field
                    name='comments'
                    component={TextArea}
                    rows={3}
                    placeholder='What is the reason for your pass?'
                  />
                )}
                <Button
                  disabled={invalid || submitting || pristine}
                  color='red'
                  type='submit'
                >
                  Request Permission
                </Button>
                <Button
                  disabled={loading}
                  onClick={
                    initialValues.id
                      ? () => history.push(`/passes/${initialValues.id}`)
                      : () => history.push('/passes')
                  }
                  type='button'
                >
                  Cancel
                </Button>
              </Form>
            </Segment>
            <Segment
              textAlign='center'
              inverted
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
              }}
            />
          </Segment.Group>
        </Grid.Column>
        <Grid.Column width={6}>
          <SchoolInfo />
          <br />
          <Image centered src='/assets/phonePreview/requestPermissionL.png' />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({ form: 'eventForm', validate, enableReinitialize: true })(
      EventForm
    )
  )
);

// import {
//   DELETE_EVENT,
//   FETCH_EVENTS
// } from './eventConstants'; // DELETE THESE COMPARE WITH FINAL
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError
// } from '../async/asyncActions';
// import { fetchSampleData } from '../../app/data/mockApi';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { toastr } from 'react-redux-toastr';
import { createNewPass } from '../../app/common/util/helper';
import firebase from '../../app/config/firebase';
import { FETCH_PASSES } from './eventConstants';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../async/asyncActions';

export const createEvent = event => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser; // Current Version firestore-redux 0.32 removes this functionality

    // Need Current Person photo for pass
    const photoURL = getState().firebase.profile.photoURL;
    // const userState = getState().firebase.profile.state;
    // const userCounty = getState().firebase.profile.countyName;
    const schoolName = getState().firebase.profile.schoolName;

    // TODO: LOOKUP SCHOOL CLASSROOM INFO FROM redux store by CLASS NAME.
    // PASS INFO (classroom, destination {destinationTeacher, UID, GPS}) TO CREATE EVENT

    // Create new Event and Add Current User Information before save
    const newEvent = createNewPass(user, schoolName, photoURL, event);
    // newEvent.id = cuid()

    try {
      dispatch(asyncActionStart());
      // Add New Pass to Firestore
      let createdEvent = await firestore.add(
        'passes', //`passes/${schoolName}`,
        newEvent
      );

      // Don't need attenddes
      // await firestore.set(`students_on_pass/${createEvent.id}_${user.uid}`, {
      //   eventId: createdEvent.id,
      //   studentName: user.uid,
      //   timeStamp: event.date,
      //   host: true
      // });

      toastr.success('Success!', 'Event has been created');

      // Return Created Event to Event Form
      dispatch(asyncActionFinish());
      return createdEvent;
    } catch (error) {
      console.log(error);
      toastr.error('Oops', 'Something went wrong ');
      dispatch(asyncActionError());
    }
  };
};

export const createPassRequest = pass => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser; // Current Version firestore-redux 0.32 removes this functionality

    // Need Current Person photo for pass
    const photoURL = getState().firebase.profile.photoURL;
    // const userState = getState().firebase.profile.state;
    // const userCounty = getState().firebase.profile.countyName;
    const schoolName = getState().firebase.profile.schoolName;

    // TODO: LOOKUP SCHOOL CLASSROOM INFO FROM redux store by CLASS NAME.
    // PASS INFO (classroom, destination {destinationTeacher, UID, GPS}) TO CREATE EVENT

    // Create new Event and Add Current User Information before save
    const newEvent = createNewPass(user, schoolName, photoURL, pass);
    // newEvent.id = cuid()

    console.log(newEvent);

    try {
      dispatch(asyncActionStart());
      // Add New Pass to Firestore
      let createdEvent = await firestore.add(
        'passRequests', //`passes/${schoolName}`,
        newEvent
      );

      // Don't need attenddes
      // await firestore.set(`students_on_pass/${createEvent.id}_${user.uid}`, {
      //   eventId: createdEvent.id,
      //   studentName: user.uid,
      //   timeStamp: event.date,
      //   host: true
      // });

      toastr.success('Success!', 'Pass Request sent for Approval to Teacher');

      // Return Created Event to Event Form
      dispatch(asyncActionFinish());
      return createdEvent;
    } catch (error) {
      console.log(error);
      toastr.error('Oops', 'Something went wrong ');
      dispatch(asyncActionError());
    }
  };
};

export const updateEvent = event => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      await firestore.update(`passes/${event.id}`, event);

      toastr.success('Success!', 'Event has been updated');
    } catch (error) {
      toastr.error('Oops', 'Something went wrong updating');
    }
  };
};

export const cancelToggle = (cancelled, eventId) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  const message = cancelToggle
    ? 'Are you sure you want to cancel this pass?'
    : 'This will re-ask your teacher';

  try {
    toastr.confirm(message, {
      onOk: async () =>
        await firestore.update(`passes/${eventId}`, {
          cancelled: cancelled
        })
    });
  } catch (error) {
    console.log(error);
  }
};

export const deletePassRequest = eventId => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      await firestore.delete(`passRequests/${eventId}`);

      toastr.success('Success!', 'Pass Request has been cancelled');

      // Return Created Event to Event Form
      dispatch(asyncActionFinish());
    } catch (error) {
      console.error('Error removing document: ', error);
      toastr.error('Oops', 'Something went wrong ');
      dispatch(asyncActionError());
    }
  };
};

// TODO: LOOKUP CLASSROOM TO SEE IF HAS LOCATION
export const getClassroomCoords = classroom => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  // Get Current School
  const schoolName = getState().firebase.profile.schoolName;

  geocodeByAddress(schoolName)
    .then(results => getLatLng(results[0]))
    .then(latlng => {
      return latlng;
    });

  // Lookup School Classroom Info
  // const firestore = getFirestore();

  // firestore.get(`school_info/${schoolName}`)
};

export const getSchoolCoords = classroom => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  // Get Current School
  const schoolName = getState().firebase.profile.schoolName;

  geocodeByAddress(schoolName)
    .then(results => getLatLng(results[0]))
    .then(latlng => {
      return latlng;
    });

  // Lookup School Classroom Info
  // const firestore = getFirestore();

  // firestore.get(`school_info/${schoolName}`)
};

export const getPassesForDashboard = lastPass => async (dispatch, getState) => {
  // get today
  let today = new Date();
  const firestore = firebase.firestore();

  // Get All Archive Passes
  const passesRef = firestore.collection('passes');

  try {
    dispatch(asyncActionStart());

    // Get Last Event in passes
    let startAfter =
      lastPass &&
      (await firestore
        .collection('passes')
        .doc(lastPass.id)
        .get());

    let query;

    // Query for Events before today and limit to 2 to enable scrolling.
    lastPass
      ? (query = passesRef
          .where('passStart', '<', today)
          .orderBy('passStart', 'desc')
          .startAfter(startAfter)
          .limit(5))
      : (query = passesRef
          .where('passStart', '<', today)
          .orderBy('passStart', 'desc')
          .limit(5));

    let querySnapshot = await query.get();

    // Can't Get size of documents so check if no more to load, finsih
    if (querySnapshot.docs.length === 0) {
      dispatch(asyncActionFinish());
      return querySnapshot;
    }

    let passes = [];

    // Loop over querySnapshot and store into our eventRedducer
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let pass = {
        ...querySnapshot.docs[i].data(),
        id: querySnapshot.docs[i].id
      };
      passes.push(pass); // Add to Events to Return
    }

    dispatch({ type: FETCH_PASSES, payload: { passes } });
    dispatch(asyncActionFinish());

    // Return QuerySnap to Component to update UI based on size
    return querySnapshot;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getPassRequestsForDashboard = lastPass => async (
  dispatch,
  getState
) => {
  // get today
  let today = new Date();
  const firestore = firebase.firestore();

  // Get All Archive Passes
  const passesRef = firestore.collection('passRequests');

  try {
    dispatch(asyncActionStart());

    // Get Last Event in passes
    let startAfter =
      lastPass &&
      (await firestore
        .collection('passRequests')
        .doc(lastPass.id)
        .get());

    let query;

    // Query for Events before today and limit to 2 to enable scrolling.
    lastPass
      ? (query = passesRef
          .where('passStart', '<', today)
          .orderBy('passStart', 'desc')
          .startAfter(startAfter)
          .limit(5))
      : (query = passesRef
          .where('passStart', '<', today)
          .orderBy('passStart', 'desc')
          .limit(5));

    let querySnapshot = await query.get();

    // Can't Get size of documents so check if no more to load, finsih
    if (querySnapshot.docs.length === 0) {
      dispatch(asyncActionFinish());
      return querySnapshot;
    }

    let passes = [];

    // Loop over querySnapshot and store into our eventRedducer
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let pass = {
        ...querySnapshot.docs[i].data(),
        id: querySnapshot.docs[i].id
      };
      passes.push(pass); // Add to Events to Return
    }

    dispatch({ type: FETCH_PASSES, payload: { passes } });
    dispatch(asyncActionFinish());

    // Return QuerySnap to Component to update UI based on size
    return querySnapshot;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const addPassComment = (passId, values) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const profile = getState().firebase.profile;
  const user = firebase.auth().currentUser;
  let newComment = {
    displayName: profile.displayName,
    photoURL: profile.photoURL || '/assets/user.png',
    uid: user.uid,
    text: values.comment,
    date: Date.now()
  };

  try {
    dispatch(asyncActionStart());
    await firebase.push(`pass_chat/${passId}`, newComment);
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Probelm adding Comment');
    dispatch(asyncActionError());
  }
};

import React from 'react';
import {
  Grid,
  Header,
  Icon,
  Divider,
  Segment,
  Container,
  List,
  Image
} from 'semantic-ui-react';
import ContactInfo from './contactus/ContactInfo';
import MeetTheTeam from './team/MeetTheTeam';
import Footer from '../nav/NavBar/Footer';
import FollowUs from './FollowUs';
import AppStoreLogosSidebar from './appStore/AppStoreLogosSidebar';

const TermsService = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='book' color='red' />
          Terms & Services
        </Header>
        <em>Last Modified: July 10, 2019</em>
        <Divider />
        <Segment>
          <Header>Welcome!</Header>{' '}
          <p>
            We’re thrilled you’ve decided to use School Pass Live! which we
            refer to simply as the “Services.”
          </p>{' '}
          <p>
            We’ve drafted these Terms of Service (which we call the “Terms”) so
            you’ll know the rules that govern our relationship with you.
            Although we have tried our best to strip the legalese from the
            Terms, there are places where these Terms may still read like a
            traditional contract. There’s a good reason for that: These Terms do
            indeed form a legally binding contract between you and School Pass
            Live!. So please read them carefully.
          </p>{' '}
          <p>
            By using the Services, you agree to the Terms. Of course, if you
            don’t agree with them, then don’t use the Services.
          </p>{' '}
          <p>
            IMPORTANT—IF YOU ARE UNDER THE AGE OF 18, YOU MUST HAVE YOUR PARENT
            OR GUARDIAN READ THE AGREEMENT BELOW AND AGREE TO IT FOR YOU. IF YOU
            DON'T GET YOUR PARENT OR GUARDIAN TO READ AND AGREE TO THIS, YOU
            DON'T HAVE PERMISSION TO USE SCHOOL PASS LIVE!. ALWAYS GET AN
            ADULT'S PERMISSION BEFORE GOING ONLINE.
          </p>
        </Segment>
        <Segment>
          <Container>
            <Header>1. Who Can Use the Services</Header>{' '}
            <p>
              We may offer additional Services with additional terms that may
              require you to be even older to use them. So please read all terms
              carefully.
            </p>{' '}
            By using the Services, you state that:
            <List bulleted>
              <List.Item>
                You can form a binding contract with School Pass Live!{' '}
              </List.Item>
              <List.Item>
                You will comply with these Terms and all applicable local,
                state, national, and international laws, rules, and regulations.
              </List.Item>
            </List>
            <p>
              If you are using the Services on behalf of a business or some
              other entity, you state that you are authorized to grant all
              licenses set forth in these Terms and to agree to these Terms on
              behalf of the business or entity. If you are using the Services on
              behalf of an entity of the U.S. Government you will notify us at{' '}
              <a href='mailto:help@schoolpasslive.com'>
                help@schoolpasslive.com
              </a>
              .
            </p>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>2. Rights We Grant You</Header>
            <p>
              School Pass Live! grants you a personal, worldwide, royalty-free,
              non-assignable, nonexclusive, revocable, and non-sublicensable
              license to access and use the Services. This license is for the
              sole purpose of letting you use and enjoy the Services’ benefits
              in a way that these Terms and our usage policies.ow.
            </p>{' '}
            <p>
              Any software that we provide you may automatically download and
              install upgrades, updates, or other new features. You may be able
              to adjust these automatic downloads through your device’s
              settings.
            </p>
            <p>
              You may not copy, modify, distribute, sell, or lease any part of
              our Services, nor may you reverse engineer or attempt to extract
              the source code of that software, unless laws prohibit these
              restrictions or you have our written permission to do so.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>3. Rights You Grant Us</Header>
            <p>
              Many of our Services let you create, upload, post, send, receive,
              and store content. When you do that, you retain whatever ownership
              rights in that content you had to begin with. But you grant us a
              license to use that content. How broad that license is depends on
              which Services you use and the Settings you have selected.
            </p>{' '}
            <p>
              For all crowd-spourced Service, you grant School Pass Live! Inc.
              and our affiliates a worldwide, royalty-free, sublicensable, and
              transferable license to host, store, use, display, reproduce,
              modify, adapt, edit, publish, and distribute that content. This
              license is for the limited purpose of operating, developing,
              providing, promoting, and improving the Services and researching
              and developing new ones.
            </p>{' '}
            <p>
              While we’re not required to do so, we may access, review, screen,
              and delete your content at any time and for any reason, including
              if we think your content violates these Terms. You alone, though,
              remain responsible for the content you create, upload, post, send,
              or store through the Service.{' '}
            </p>{' '}
            <p>
              The Services may contain advertisements. In consideration for
              School Pass Live!. letting you access and use the Services, you
              agree that we, our affiliates, and our third-party partners may
              place advertising on the Services. Because the Services contain
              content that you and other users provide us, advertising may
              sometimes appear near your content.
            </p>
            <p>
              We always love to hear from our users. But if you volunteer
              feedback or suggestions, just know that we can use your ideas
              without compensating you.
            </p>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>4. Privacy</Header>{' '}
            <p>
              Your privacy matters to us. You can learn how we handle your
              information when you use our Services by reading our Privacy
              Policy Below. We encourage you to give the Privacy Policy a
              careful look because, by using our Services, you agree that School
              Pass Live! can collect, use, and share your information consistent
              with that policy.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>5. Respecting Other People’s Rights</Header>{' '}
            <p>
              School Pass Live!. respects the rights of others. And so should
              you. You therefore may not use the Services in a manner that:
            </p>{' '}
            <List bulleted>
              <List.Item>
                violates or infringes someone else’s rights of publicity,
                privacy, copyright, trademark, or other intellectual-property
                right.
              </List.Item>
              <List.Item>bullies, harasses, or intimidates. </List.Item>
              <List.Item>defames.</List.Item>
              <List.Item>spams or solicits our users. </List.Item>
            </List>
            <p>
              You must also respect School Pass Live! rights. These Terms do not
              grant you any right to:
            </p>{' '}
            <List bulleted>
              <List.Item>
                use branding, logos, designs, photographs, videos, or any other
                materials used in our Services.
              </List.Item>
              <List.Item>
                copy, archive, download, upload, distribute, syndicate,
                broadcast, perform, display, make available, or otherwise use
                any portion of the Services or the content on the Services
                except as set forth in these Terms.
              </List.Item>
              <List.Item>
                use the Services, any tools provided by the Services, or any
                content on the Services for any commercial purposes without our
                consent.
              </List.Item>
            </List>
            <p>
              In short: You may not use the Services or the content on the
              Services in ways that are not authorized by these Terms. Nor may
              you help anyone else in doing so.
            </p>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>6. Safety</Header>
            <p>
              We try hard to keep our Services a safe place for all users. But
              we can’t guarantee it. That’s where you come in. By using the
              Services, you agree that:
            </p>{' '}
            <List bulleted>
              <List.Item>
                <List.Item>
                  You will not use the Services for any purpose that is illegal
                  or prohibited in these Terms.
                </List.Item>
              </List.Item>
              <List.Item>
                You will not use any robot, spider, crawler, scraper, or other
                automated means or interface to access the Services or extract
                other user’s information.
              </List.Item>
              <List.Item>
                You will not use or develop any third-party applications that
                interact with the Services or other users’ content or
                information without our written consent.
              </List.Item>
              <List.Item>
                You will not use the Services in a way that could interfere
                with, disrupt, negatively affect, or inhibit other users from
                fully enjoying the Services, or that could damage, disable,
                overburden, or impair the functioning of the Services.
              </List.Item>
              <List.Item>
                You will not use or attempt to use another user’s account,
                username, or password without their permission.
              </List.Item>
              <List.Item>
                You will not solicit login credentials from another user. You
                will not post content that contains pornography, graphic
                violence, threats, hate speech, or incitements to violence.
              </List.Item>
              <List.Item>
                You will not upload viruses or other malicious code or otherwise
                compromise the security of the Services.
              </List.Item>
              <List.Item>
                You will not attempt to circumvent any content-filtering
                techniques we employ, or attempt to access areas or features of
                the Services that you are not authorized to access.
              </List.Item>
              <List.Item>
                You will not probe, scan, or test the vulnerability of our
                Services or any system or network.
              </List.Item>
              <List.Item>
                You will not encourage or promote any activity that violates
                these Terms.
              </List.Item>
            </List>
            <p>
              We also care about your safety while using our Services. So do not
              use our Services in a way that would distract you from obeying
              traffic or safety laws.
            </p>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>7. Your Account</Header>{' '}
            <p>
              You are responsible for any activity that occurs in your School
              Pass Live! account. So it’s important that you keep your account
              secure. One way to do that is to select a strong password that you
              don’t use for any other account.
            </p>{' '}
            By using the Services, you agree that, in addition to exercising
            common sense:
            <List bulleted>
              <List.Item>
                You will not create more than one account for yourself.
              </List.Item>
              <List.Item>
                You will not create another account if we have already disabled
                your account, unless you have our written permission to do so.
              </List.Item>
              <List.Item>You will not share your password.</List.Item>
              <List.Item>
                You will not log in or attempt to access the Services through
                unauthorized third-party applications or clients.
              </List.Item>
            </List>
            <p>
              If you think that someone has gained access to your account,
              please immediately reach out to{' '}
              <a href='mailto:help@schoolpasslive.com'>
                help@schoolpasslive.com
              </a>{' '}
              .
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>8. Pass History</Header>
            <p>
              Pass History is our data-storage service that makes it easier for
              you to reminisce and keep track of your passes, anywhere. By
              agreeing to these Terms, you automatically enable Pass History.
              Your content in Pass History might become unavailable for any
              number of reasons, including things like an operational glitch or
              a decision on our end to terminate your account.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>9. Data Charges and Mobile Phones </Header>
            <p>
              You are responsible for any mobile charges that you may incur for
              using our Services, including text-messaging and data charges. If
              you’re unsure what those charges may be, you should ask your
              service provider before using the Services.
            </p>
            <p>
              If you change or deactivate the mobile phone number that you used
              to create a School Pass Live! account, you must update your
              account information through Settings within 72 hours to prevent us
              from sending to someone else messages intended for you.
            </p>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>10. Extra Credit Shop / In App Purchases</Header>
            <p>
              With optional features in the Extra Credit shop, you can also
              purchase individual features like Send Messages, Student Search,
              or auto-renewing A+ Account. These features will expire after 1
              school year.
            </p>{' '}
            With these optional purchases, you will can use the following
            additional capabilities.
            <List as='ol'>
              <List.Item as='li' value='-'>
                Set Pass Time Limits
              </List.Item>
              <List.Item as='li' value='-'>
                Contact all students out of class during Code Red and Fire Drill
                procedures
              </List.Item>
              <List.Item as='li' value='-'>
                Search Student Pass History
              </List.Item>
              <List.Item as='li' value='-'>
                Communicate to students utilizing School Hall Pass
              </List.Item>
            </List>
            <p>
              <strong>LENGTH</strong>
              <br /> All School Pass Live! purchases are made in the Extra
              Credit Shop on the web app. Each feature is valid for 1 calendar
              year and will need to be repurchased with the exception of A+
              Account that is auto-renewed.
            </p>
            By using the Services, you state that:
            <List bulleted>
              <List.Item>
                {' '}
                Payment will be charged to iTunes or Google Play Account at
                confirmation of purchase{' '}
              </List.Item>
              <List.Item>
                <strong>A+ Account</strong> Subscription automatically renews
                unless auto-renew is turned off at least 24-hours before the end
                of the current period
                <List.List as='ol'>
                  <List.Item as='li' value='-'>
                    {' '}
                    Account will be charged for renewal within 24-hours prior to
                    the end of the current period, and identify the cost of the
                    renewal
                  </List.Item>
                  <List.Item as='li' value='-'>
                    Subscriptions may be managed by the user and auto-renewal
                    may be turned off by going to the user’s Account Settings
                    after purchase.
                  </List.Item>
                </List.List>
              </List.Item>
            </List>
            <p>
              <strong>
                NON AUTO-RENEWING FEATURES (In App Purchases) $1.99 annually
              </strong>
            </p>
            The following non-renewing features that are valid for 1 year from
            purchase for $1.99 each
            <List bulleted>
              <List.Item>MESSAGING: send messages to other users</List.Item>
              <List.Item>SEARCH: search students pass history</List.Item>
            </List>
            An auto-renewing subscription through an In-App Purchase. (If you do
            not maintain a subscription you will have access to all the free
            features)
            <List bulleted>
              <List.Item>Auto-renewable subscription</List.Item>
              <List.Item>1 year ($1.99) each</List.Item>
              <List.Item>
                Your iTunes account will be charged $1.99 per feature at
                confirmation of purchase. Any of the features purchased will be
                valid for 1 year of access. At the end of your year, the feature
                will be turned off unless you repurchase that feature.
              </List.Item>
            </List>
            <p>
              {' '}
              <strong>
                AUTO-RENEWING FEATURES (In App Purchases) $9.99 annually
              </strong>
            </p>
            <List bulleted>
              <List.Item>
                A+ ACCOUNT: No Full screen ads, & all features(messaging,
                search) An auto-renewing subscription through an In-App
                Purchase. (If you do not maintain a subscription you will have
                access to all the free features)
              </List.Item>
              <List.Item>Auto-renewable subscription</List.Item>
              <List.Item>1 year ($9.99)</List.Item>
              <List.Item>
                Your subscription will be charged to your iTunes or Google Play
                account at confirmation of purchase and will automatically renew
                (at the duration selected) unless auto-renew is turned off at
                least 24 hours before the end of the current period
              </List.Item>
              <List.Item>
                Current subscription may not be cancelled during the active
                subscription period; however, you can manage your subscription
                and/or turn off auto-renewal by visiting your iTunes Account
                Settings after purchase
              </List.Item>
            </List>
          </Container>{' '}
          <Divider />
          <Container>
            <Header>11. Third-Party Services</Header> If you use a service,
            feature, or functionality that is operated by a third party and made
            available through our Services (including Services we jointly offer
            with the third party), each party’s terms will govern the respective
            party’s relationship with you. School Pass Live! is not responsible
            or liable for a third party’s terms or actions taken under the third
            party’s terms.
          </Container>{' '}
          <Divider />
          <Container>
            <Header>12. Modifying the Services and Termination</Header>
            <p>
              We’re relentlessly improving our Services and creating new ones
              all the time. That means we may add or remove features, products,
              or functionalities, and we may also suspend or stop the Services
              altogether. We may take any of these actions at any time, and when
              we do, we may not provide you with any notice beforehand.
            </p>{' '}
            <p>
              {' '}
              While we hope you remain a lifelong School Pass Live! user, you
              can terminate these Terms at any time and for any reason by
              deleting your account.
            </p>
            <p>
              School Pass Live!. may also terminate these Terms with you at any
              time, for any reason, and without advanced notice. That means that
              we may stop providing you with any Services, or impose new or
              additional limits on your ability to use our Services. For
              example, we may deactivate your account due to prolonged
              inactivity, and we may reclaim your username at any time for any
              reason.
            </p>
            <p>
              Regardless of who terminates these Terms, both you and School Pass
              Live!. continue to be bound by Sections 3, 6, 9, 10, and 13-22 of
              the Terms.
            </p>{' '}
          </Container>
          <Divider />
          <Container>
            <Header>13. Indemnity</Header> You agree, to the extent permitted by
            law, to indemnify, defend, and hold harmless School Pass Live!., our
            affiliates, directors, officers, stockholders, employees, licensors,
            and agents from and against any and all complaints, charges, claims,
            damages, losses, costs, liabilities, and expenses (including
            attorneys’ fees) due to, arising out of, or relating in any way to:
            (a) your access to or use of the Services; (b) your content; and (c)
            your breach of these Terms.
          </Container>
          <Divider />
          <Container>
            <Header>14. Disclaimers</Header>
            <p>
              We try to keep the Services up and running and free of annoyances.
              But we make no promises that we will succeed.
            </p>{' '}
            <p>
              THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE
              EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE SCHOOL PASS LIVE!.
              ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR
              WARRANT THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE,
              OR TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS,
              DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY CONTENT, USER
              CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL
              BE TIMELY OR ACCURATE.
            </p>
            <p>
              SCHOOL PASS LIVE!. TAKES NO RESPONSIBILITY AND ASSUMES NO
              LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY
              CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH
              OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO
              CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE
              INAPPROPRIATE, NONE OF WHICH SCHOOL PASS LIVE!. WILL BE
              RESPONSIBLE FOR.
            </p>{' '}
          </Container>
          <Divider />
          <Container>
            <Header>15. Limitation of Liability</Header> TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, SCHOOL PASS LIVE. AND OUR MANAGING MEMBERS,
            SHAREHOLDERS, EMPLOYEES, AFFILIATES, LICENSORS, AND SUPPLIERS WILL
            NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
            WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
            GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR
            ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B)
            THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH
            THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR
            CONTENT, EVEN IF SCHOOL PASS LIVE!. HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL SCHOOL PASS LIVE!
            AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED
            THE GREATER OF $100 USD OR THE AMOUNT YOU PAID SCHOOL PASS LIVE!, IF
            ANY, IN THE LAST 12 MONTHS.
          </Container>
          <Divider />
          <Container>
            <Header>16. Final Terms</Header> These Terms (together with any
            additional terms applicable to specific Services you use) make up
            the entire agreement between you and School Pass Live! and supersede
            any prior agreements. These Terms do not create or confer any
            third-party beneficiary rights. If we do not enforce a provision in
            these Terms, it will not be considered a waiver. We reserve all
            rights not expressly granted to you. You may not transfer any of
            your rights or obligations under these Terms without our consent.
          </Container>
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}>
        <ContactInfo />
        <FollowUs />
        <AppStoreLogosSidebar />
        <MeetTheTeam showImages={true} />
        <Divider />
        <Image src='/assets/phonePreview/requestPermissionL.png' size='huge' />
        <br />
        <Image src='/assets/phonePreview/passHistoryL.png' size='huge' />
        <br />
        <Image src='/assets/phonePreview/menuClassPassL.png' size='huge' />
      </Grid.Column>
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default TermsService;

/*global google*/
import React, { useState } from 'react';
import { Segment, Grid, Icon, Button } from 'semantic-ui-react';
import PassDetailedMap from './PassDetailedMap';
// import { GeoPoint } from "firebase";

const PassDetailInfo = ({ event, showMap }) => {
  const [isMapOpen, showMapToggle] = useState(showMap || true);

  return (
    <Segment.Group>
      {isMapOpen && (
        <PassDetailedMap
          lat={event.departLatLng ? event.departLatLng.latitude : 0.0}
          lng={event.departLatLng ? event.departLatLng.longitude : 0.0}
          mapTypeID={google.maps.MapTypeId.SATELLITE}
          minZoom={18}
          maxZoom={21}
          showMarker={true}
          markerName={'School'}
        />
      )}
      <Segment attached>
        <Grid verticalAlign='top'>
          <Grid.Column width={1}>
            <Icon name='marker' size='large' color='teal' />
          </Grid.Column>
          <Grid.Column width={11}>
            <span>{event.schoolName}</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              color={isMapOpen ? 'black' : 'teal'}
              onClick={() => showMapToggle(!isMapOpen)}
              size='tiny'
              content={isMapOpen ? 'Hide Map' : 'Show Map'}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

export default PassDetailInfo;

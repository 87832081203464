import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class extends React.Component {
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={150}
        naturalSlideHeight={55}
        totalSlides={4}
        interval={3500}
        isPlaying={true}
        touchEnabled={true}
      >
        <Slider>
          <Slide index={0}>
            <Link to='/about/info'>
              <Image src='/assets/carousel/getApp.jpg' />
            </Link>
          </Slide>
          <Slide index={1}>
            <Link to='/about/info'>
              <Image src='/assets/carousel/passHisotry.jpg' />
            </Link>
          </Slide>
          <Slide index={2}>
            <Link to='/about/info'>
              {' '}
              <Image src='/assets/carousel/webCloud.jpg' />
            </Link>
          </Slide>
          <Slide index={3}>
            <Link to='/about/info'>
              <Image src='/assets/carousel/easyPassApprove.jpg' />
            </Link>
          </Slide>
        </Slider>
      </CarouselProvider>
    );
  }
}

import React, { Component, createRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header, Icon, Divider, Grid, Segment } from 'semantic-ui-react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import MeetTheTeam from '../team/MeetTheTeam';
import Geno from './Geno';
import Karen from './Karen';
import Alicia from './Alicia';
import Jamie from './Jamie';
import ContactInfo from '../contactus/ContactInfo';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';
import Footer from '../../nav/NavBar/Footer';
import FollowUs from '../FollowUs';

const mapState = (state, ownProps) => {
  const teamMember = ownProps.match.params.member;
  return {
    teamMember
  };
};

class TeamMember extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    const { teamMember } = this.props;
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={10}>
          <div ref={this.contextRef}>
            <Header size='huge'>
              <Icon name='handshake' color='red' />
              Meet The Team
            </Header>
            <Divider />
            <Segment.Group>
              <Segment
                inverted
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${chalkboard})`,
                  padding: '30px'
                }}
              >
                <Fragment>
                  {teamMember === 'geno' && <Geno />}
                  {teamMember === 'karen' && <Karen />}
                  {teamMember === 'alicia' && <Alicia />}
                  {teamMember === 'jamie' && <Jamie />}
                </Fragment>
              </Segment>
              <Segment
                textAlign='center'
                inverted
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${chalkboard})`
                }}
              />
            </Segment.Group>
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <ContactInfo />
          <MeetTheTeam showImages={true} />
          <FollowUs />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}
export default connect(mapState)(TeamMember);

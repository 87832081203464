import React, { Fragment } from 'react';
import {
  Segment,
  Header,
  Icon,
  Container,
  Image,
  Grid,
  Divider,
  Label
} from 'semantic-ui-react';
import FollowUs from '../FollowUs';
import Footer from '../../nav/NavBar/Footer';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';

const OurStory = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='newspaper outline' color='red' />
          Our Story
        </Header>
        <Divider />
        <Segment>
          <Fragment>
            <Segment.Group>
              <Segment
                inverted
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${chalkboard})`,
                  padding: '30px'
                }}
              >
                <Container>
                  <br />
                  <iframe src='https://www.youtube.com/embed/2S8KFXNerpI'
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  width="100%"
                  />
                  <br /><br />
                  <Label color='red'>
                    <Icon name='info circle' />
                    Created By a High School Computer Teacher
                  </Label>{' '}
                  <br />
                  <p>
                    <span className='chalkboardWhiteTitle'>
                      School Pass Live
                    </span>{' '}
                    was created in Miami by the high school Computer Science
                    teacher,{' '}
                    <a
                      href='https://www.linkedin.com/in/ganttech/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>Jamie Gant</span>
                    </a>
                    . Jamie teaches Computer Science and Virtual Reality Coding
                    at{' '}
                    <a
                      href='https://www.ronaldreaganseniorhighschool.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>
                        Ronald Reagan/Doral Sr. High School
                      </span>
                    </a>
                    . Mr. Gant also trains Educators nationwide on how to
                    incorporate coding and computer science into all classrooms
                    through his{' '}
                    <a
                      href='http://ganttech.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>Ganttech.com</span>
                    </a>{' '}
                    website. Jamie shares the loves of teaching that was gained
                    from his family of educators; his mom and all 3 children are
                    teachers in{' '}
                    <a
                      href='http://www.dadeschools.net/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>
                        Miami Dade County Public Schools
                      </span>
                    </a>
                    .
                  </p>{' '}
                  <Divider />
                  <Label color='red'>
                    <Icon name='calendar alternate' />
                    2017-2018 School Year: Origin - Loss of Instructional Time
                  </Label>{' '}
                  <br />
                  <p>
                    Initially, Jamie thought about this idea because in{' '}
                    <a
                      href='http://www.dadeschools.net/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>
                        Miami Dade County Public Schools
                      </span>
                    </a>
                    , teachers are required to write this yellow paper hall pass
                    <Image
                      verticalAlign='top'
                      floated='left'
                      src='/assets/traditionalPass/passMdps.png'
                    />{' '}
                    when a student requests to leave the classroom. Mr. Gant
                    loves teaching and hates having to stop to fill out the
                    student name, date, where they are going, etc. Being a
                    coding teacher, he individually coded every line of code for
                    iOS, android, and this website. His motto for his students
                    is "I Can, You Can, We Can Change the World with Technology
                    & Computer Science" so he created this digital hall pass for
                    that students use to leave his classroom. This school year I
                    built out my 1st version of this app and tested it with my
                    students in the classroom.
                  </p>
                  <Divider />
                  <Label color='red'>
                    <Icon name='calendar alternate' />
                    Feb 10th, 2018: Things Changed - Marjory Stoneman Douglas
                    Sr. High
                  </Label>{' '}
                  <br />
                  <p>
                    Things changed in February 2018. Jamie was conducting a
                    training at{' '}
                    <a
                      href='https://www.cis.fiu.edu/ultimate-software-academy-for-computer-science-education-fiu/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>
                        Florida International University
                      </span>
                    </a>{' '}
                    for educators on Saturday February 10th. At my trainings I
                    mentioned my digital pass that I use to get feedback from
                    educators. Most of the teachers ask when can I release it
                    for them to use in their classroom. One of the teachers in
                    attendance at this training was a friend of mine, Sandra
                    Rennie from{' '}
                    <a
                      href='https://www.browardschools.com/stonemandouglas'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='chalkboardCyan'>
                        Marjory Stoneman Douglas
                      </span>
                    </a>
                    . Just 4 days, later the shooting at her school happened
                    only 30 minutes away from Mr. Gant. The following Saturday
                    at next training session, another Broward Teacher mentioned
                    Jamie that pass thing and knowing where students are in the
                    event of something happening at a school is needed. This
                    tragedy impacted South Florida tremendously and also started
                    a school safety conversation nationwide. Knowing Sandra,
                    Jamie was inspired to completely redesign his digital pass
                    into{' '}
                    <em>
                      <strong>School Pass Live!</strong> - "Safer, Easier,
                      Smarter, Passes For Classes"
                    </em>
                    .
                  </p>
                  <Divider />
                  <Label color='red'>
                    <Icon name='calendar alternate' />
                    2018-2019 School Year: Threat At My School
                  </Label>{' '}
                  <br />
                  <p>
                    On April 4, 2019, Mr. Gant recieved this message from his
                    Principal.{' '}
                    <Image
                      verticalAlign='top'
                      floated='left'
                      src='/assets/school/reaganThreat2019.jpg'
                    />
                    A student at Reagan/Doral posted on Social Media that he was
                    coming to school to shoot it up the following day. Luckily
                    Officers were able to get to this student the night before.
                    Mr. Gant more than ever said realized this national threat
                    and worked to finish School Pass Live to launch for the fall
                    of 2019.
                  </p>
                  <Divider />
                  <Label color='red'>
                    <Icon name='calendar alternate' />
                    2019-2020 School Year: LAUNCH
                  </Label>{' '}
                  <br />
                  <Header textAlign='center' color='olive'>
                    SCHOOL PASS LIVE! AVAILABLE FOR EVERYONE!!!
                  </Header>
                </Container>
              </Segment>
              <Segment
                textAlign='center'
                inverted
                style={{
                  backgroundSize: 'cover',
                  backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
                }}
              />
            </Segment.Group>
          </Fragment>
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}>
        <Image src='/assets/phonePreview/passHistoryXL.png' />
        <FollowUs />
      </Grid.Column>{' '}
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default OurStory;

import React, { Fragment } from 'react';
import { Segment, Header, Label, Item, Divider, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const ContactInfo = () => {
  return (
    <Fragment>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        inverted
        color='black'
      >
        <Header>Contact Info</Header>
      </Segment>
      <Segment attached>
        <Item>
          <Item.Content>
            <Item.Description>
              <Label>
                <Icon name='mail square' /> Email
              </Label>
            </Item.Description>
            <Item.Meta>
              <em>Help Desk</em> <br/> <a href='mailto:help@schoolpasslive.com?subject=Help With School Pass Live!'>
                help@schoolpasslive.com
              </a><br/>
              <em>CEO/ High School Teacher</em><br/> <a href='mailto:jamiegant@schoolpasslive.com?subject=Help With School Pass Live!'>
                jamiegant@schoolpasslive.com
              </a>
            </Item.Meta>
          </Item.Content>
        </Item>
        <Divider />
        <Item>
          <Item.Content>
            <Item.Description>
              <Label>
                <Icon name='phone' /> Phone
              </Label>
            </Item.Description>
            <Item.Meta>305-725-0668</Item.Meta>
          </Item.Content>
        </Item>
        <Divider />
        <Label>
          <Icon name='mail' /> Mail
        </Label>
        <Item>
          <Item.Content>
            <Item.Description>School Pass Live!, Inc.</Item.Description>
            <Item.Extra as={Link} to='/about'>
              6193 NW 183rd ST #171503
            </Item.Extra>
            <br />{' '}
            <Item.Extra as={Link} to='/about'>
              Hialeah, FL 33015
            </Item.Extra>
          </Item.Content>
        </Item>
        <Item />
      </Segment>
    </Fragment>
  );
};

export default ContactInfo;

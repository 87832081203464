import React, { Component, createRef } from 'react';
import { Header, Icon, Divider, Grid, Segment } from 'semantic-ui-react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import ClassroomsList from './classrooms/ClassroomsList';
import Footer from '../../nav/NavBar/Footer';
import PassDetailedMap from '../../event/PassDetailed/PassDetailedMap';
import AddClassroomsForm from '../../school/classrooms/AddClassroomsForm';

class Classrooms extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true,
    latlng: {
      lat: 25.852687,
      lng: -80.371362
    }
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='building' color='red' />
            School Classrooms
          </Header>
          <Divider />
          <div ref={this.contextRef}>
            <PassDetailedMap
              key={this.state.latlng.lng}
              latlng={this.state.latlng}
              showSatellite={true}
              zoomLevel={17}
            />{' '}
            <Divider />
            <ClassroomsList />
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment
            textAlign='center'
            style={{ border: 'none' }}
            attached='top'
            inverted
          >
            <Header>Add Classroom</Header>
          </Segment>
          <Segment attached textAlign='center'>
            <AddClassroomsForm />
          </Segment>
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}
export default Classrooms;

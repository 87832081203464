import React, { Component } from 'react';
import {
  Segment,
  Form,
  Header,
  Divider,
  Button,
  Grid
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
// import DateInput from '../../../app/common/form/DateInput';
import PlaceInput from '../../../app/common/form/PlaceInput';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import RadioInput from '../../../app/common/form/RadioInput';
// import { addYears } from 'date-fns';
import {
  composeValidators,
  hasLengthGreaterThan,
  combineValidators,
  isRequired
} from 'revalidate';
import { geocodeByAddress } from 'react-places-autocomplete';
import Footer from '../../nav/NavBar/Footer';
import { chalkboard } from '../../../srcAssets/chalkboard/chalkboard.jpg';

const validate = combineValidators({
  accountType: isRequired({ message: 'Account Type is required' }),
  displayName: composeValidators(
    isRequired({ message: 'Please enter a description' }),
    hasLengthGreaterThan(4)({
      message: 'Description needs to be at least 5 characters'
    })
  )(),
  phone: isRequired('phone'),
  city: isRequired('city'),
  schoolName: isRequired('Please select a school Name')
});

const studentGrades = [
  // { key: 'noSelection', text: '-----', value: '' },
  // { key: 'prek', text: 'Pre-K', value: 'Pre-K' },
  // { key: 'kindergarden', text: 'Kindergarden', value: 'Kindergarden' },
  // { key: 'grade1', text: '1st Grade', value: '1st Grade' },
  // { key: 'grade2', text: '2nd Grade', value: '2nd Grade' },
  // { key: 'grade3', text: '3rd Grade', value: '3rd Grade' },
  // { key: 'grade4', text: '4th Grade', value: '4th Grade' },
  // { key: 'grade5', text: '5th Grade', value: '5th Grade' },
  { key: 'grade6', text: '6th Grade', value: '6th Grade' },
  { key: 'grade7', text: '7th Grade', value: '7th Grade' },
  { key: 'grade8', text: '8th Grade', value: '8th Grade' },
  { key: 'grade9', text: '9th Grade', value: '9th Grade' },
  { key: 'grade10', text: '10th Grade', value: '10th Grade' },
  { key: 'grade11', text: '11th Grade', value: '11th Grade' },
  { key: 'grade12', text: '12th Grade', value: '12th Grade' }
];

const educatorJobs = [
  // { key: 'noSelection', text: '-----', value: '' },
  { key: 'administrator', text: 'Administrator', value: 'restroom' },
  { key: 'teacher', text: 'Teacher', value: 'auditorium' },
  { key: 'security', text: 'Cafeteria', value: 'cafeteria' },
  { key: 'paraprofesional', text: 'Classroom', value: 'classroom' },
  { key: 'other', text: 'Clinic', value: 'clinic' }
];

class InfoNeededForm extends Component {
  state = {
    cityLatLng: {},
    schoolLatLng: {},
    isEducator: false,
    positionOptions: studentGrades
  };

  onFormSubmit = values => {
    // Add Values for LatLng when create Event
    values.schoolCoordinates = this.state.schoolLatLng;

    // Check

    // Update the User information
    this.props.handleSubmit(this.props.updateProfile);
  };

  handleAccountSelect = accountType => {
    this.setState({
      isEducator: accountType === 'Educator'
    }).then(() => {
      this.setState({
        positionOptions: this.isEducator ? educatorJobs : studentGrades
      });

      this.props.change('position', '');
    });
  };

  handleCitySelect = selectedCity => {
    geocodeByAddress(selectedCity)
      .then(latlng => {
        this.setState({
          cityLatLng: latlng
        });
      })
      .then(() => {
        this.props.change('city', selectedCity);
      });
  };

  render() {
    const { pristine, submitting } = this.props;
    return (
      <Grid>
        <Grid.Column width={10}>
          <Segment.Group>
            <Segment
              inverted
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${chalkboard})`,
                padding: '30px'
              }}
            >
              <Header dividing size='large' content='My Information' />
              <Form>
                <Form.Group inline>
                  <Field
                    name='accountType'
                    type='radio'
                    value='Student'
                    label='Student'
                    color='white'
                    component={RadioInput}
                    onSelect={this.handleAccountSelect}
                  />
                  <Field
                    name='accountType'
                    type='radio'
                    value='Educator'
                    label='Educator'
                    component={RadioInput}
                    onSelect={this.handleAccountSelect}
                  />
                </Form.Group>
                <Field
                  width={5}
                  name='displayName'
                  type='text'
                  component={TextInput}
                  placeholder='Full Name'
                />
                <Field
                  width={8}
                  name='phone'
                  type='text'
                  component={TextInput}
                  placeholder='Phone Number'
                />
                {/* <Field
            width={8}
            name='dateOfBirth'
            component={DateInput}
            placeholder='Date of Birth'
            dateFormat='dd LLL yyyy'
            showYearDropdown={true}
            showMonthDropdown={true}
            dropdownMode='select'
            maxDate={addYears(new Date(), -13)}
          /> */}
                <Field
                  name='city'
                  placeholder='City'
                  autoComplete='off'
                  options={{ types: ['(cities)'] }}
                  label='Female'
                  component={PlaceInput}
                  onSelect={this.handleCitySelect}
                  width={8}
                />
                <Field
                  name='position'
                  component={SelectInput}
                  options={this.isEducator ? educatorJobs : studentGrades}
                  placeholder='School Position'
                />
                <Field
                  width={8}
                  name='schoolId'
                  type='text'
                  component={TextInput}
                  placeholder='School ID #'
                />
                <Divider />
                <Button
                  disabled={pristine || submitting}
                  size='large'
                  positive
                  content='Update Profile'
                />
              </Form>
            </Segment>
            <Segment
              textAlign='center'
              inverted
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
              }}
            />
          </Segment.Group>
        </Grid.Column>

        <Grid.Column width={6} />
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({
  form: 'userProfile',
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false
})(InfoNeededForm);

import React from 'react';
import { Container, Header } from 'semantic-ui-react';

const SloganSchool = () => {
  return (
    <Container textAlign='center'>
      <Header textAlign='center' color='red'>
        "SAFER, EASIER, SMARTER, PASSES FOR CLASSES" ™
      </Header>
      Increase instructional time, student safety, & student accountability.
    </Container>
  );
};

export default SloganSchool;

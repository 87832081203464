import React from 'react';
import {
  Grid,
  Header,
  Icon,
  Divider,
  Segment,
  Container,
  List,
  Image
} from 'semantic-ui-react';
import ContactInfo from './contactus/ContactInfo';
import FollowUs from './FollowUs';
import MeetTheTeam from './team/MeetTheTeam';
import Footer from '../nav/NavBar/Footer';
import AppStoreLogosSidebar from './appStore/AppStoreLogosSidebar';

const PrivacyPolicy = () => {
  return (
    <Grid>
      <Grid.Column width={10}>
        <Header size='huge'>
          <Icon name='shield' color='red' />
          Privacy Policy
        </Header>
        <em>Last Modified: July 10, 2019</em>
        <Divider />
        <Segment>
          <p>
            School Pass Live! is the solution to monitor student movement in and
            around the school building using the latest technology to better
            secure school buildings and improve instruction & learning. School
            Pass Live! is designed to provide schools with increased
            accountability, security and additional data not available with
            traditional written hall passes.
          </p>
          <p>
            {' '}
            When you use these services—and any others we in the Ganttech family
            roll out, whether in the School Pass Live app or elsewhere—you’ll
            share some information with us. We get that that can affect your
            privacy. So we want to be upfront about the information we collect,
            how we use it, whom we share it with, and the choices we give you to
            control, access, and update your information.{' '}
          </p>
          <p>
            That’s why we’ve written this Privacy Policy. And it’s why we’ve
            tried to write it in a way that’s blissfully free of the legalese
            that often clouds these documents. Of course, if you still have
            questions about anything in our Privacy Policy, just email us at
            <a href='mailto:help@schoolpasslive.com'>
              {' '}
              help@schoolpasslive.com
            </a>
          </p>
        </Segment>
        <Segment>
          <Container />
          <Header>Information We Collect</Header>
          There are three basic categories of information we collect:
          <List bulleted>
            <List.Item>
              <strong>Information you choose to give us.</strong>
            </List.Item>
            <List.Item>
              <strong>Information we get when you use our services.</strong>
            </List.Item>
            <List.Item>
              <strong>Information we get from third parties.</strong>
            </List.Item>
          </List>
          Here’s a little more detail on each of these categories.
          <Divider />
          <Container />
          <Header>Information You Choose to Give Us</Header>
          <p>
            When you interact with our services, we collect the information that
            you choose to share with us. For example, most of our services
            require you to set up a basic School Pass Live! account, so we need
            to collect a few important details about you, such as: a full name
            you’d like to go by, a password, an email address, a phone number,
            and school information. To make it easier for others to find you, we
            may also ask you to provide us with some additional information that
            will be publicly visible on our services, such as profile pictures,
            a name, or other useful identifying information. Other services,
            such as commerce products, may also require you to provide us with a
            debit or credit card number and its associated account information.{' '}
          </p>
          <p>
            Of course, you’ll also provide us whatever information you send
            through the services, such as Passes and Messages to your students
            and teachers. Keep in mind that the users you send messages you send
            could copied or saved outside the app. So, the same common sense
            that applies to the internet at large applies to School Pass Live!
            as well: Don’t send messages or share content that you wouldn’t want
            someone to save or share.
          </p>
          <p>
            It probably goes without saying, but we’ll say it anyway: When you
            contact School Pass Live! Support or communicate with us in any
            other way, we’ll collect whatever information you volunteer.
          </p>
          <Divider />
          <Container />
          <Header>Information We Get When You Use Our Services</Header>
          When you use our services, we collect information about which of those
          services you’ve used and how you’ve used them. We might know, for
          instance, that you used a pass from Room 102 teacher Jamie Gant, went
          to the restroom for period of time, and sent a few messages to
          friends. Here’s a fuller explanation of the types of information we
          collect when you use our services :
          <List bulleted>
            <List.Item>
              Usage Information. We collect information about your activity
              through our services. For example, we may collect information
              about: how you interact with the services, such as passes, which
              classrooms you leave, or GPS if provided.
            </List.Item>{' '}
            <List.Item>
              {' '}
              how you communicate with other School Pass Live Users, such as
              their names, the time and date of your communications, the number
              of messages you exchange with your friends, which friends you
              exchange messages with the most, and your interactions with
              messages.{' '}
            </List.Item>
            <List.Item>
              {' '}
              Content Information. We collect information about the content you
              provide, such as your school name, location, grade, and job.
            </List.Item>
            <List.Item>
              {' '}
              Device Information. We collect device-specific information, such
              as the hardware model, operating system version, advertising
              identifier, unique application identifiers, unique device
              identifiers, browser type, language, wireless network, and mobile
              network information (including the mobile phone number).
            </List.Item>
            <List.Item>
              {' '}
              Camera and Photos. Our account requests to collect an account
              image from your device’s camera and photos.{' '}
            </List.Item>
            <List.Item>
              {' '}
              Location Information. When you use our services we may collect
              information about your location. With your consent, we may also
              collect information about your precise location using methods that
              include GPS, wireless networks, cell towers, Wi-Fi access points,
              and other sensors, such as gyroscopes, accelerometers, and
              compasses.{' '}
            </List.Item>
            <List.Item>
              {' '}
              Information Collected by Cookies and Other Technologies. Like most
              online services and mobile applications, we may use cookies and
              other technologies, such as web beacons, web storage, and unique
              advertising identifiers, to collect information about your
              activity, browser, and device. We may also use these technologies
              to collect information when you interact with services we offer
              through one of our partners, such as advertising and commerce
              features. Most web browsers are set to accept cookies by default.
              If you prefer, you can usually remove or reject browser cookies
              through the settings on your browser or device. Keep in mind,
              though, that removing or rejecting cookies could affect the
              availability and functionality of our services.
              <List.List as='ol'>
                <List.Item>
                  a) Log Information. We also collect log information when you
                  use our app. That information includes, among other things:
                  details about how you’ve used our services.
                </List.Item>
                <List.Item>
                  {' '}
                  b) device information, such as your web browser type and
                  language{' '}
                </List.Item>
                <List.Item> c) access times.</List.Item>
                <List.Item> d) passes. </List.Item>
                <List.Item> e) IP address. </List.Item>
                <List.Item>
                  {' '}
                  f) identifiers associated with cookies or other technologies
                  that may uniquely identify your device or browser.{' '}
                </List.Item>
                <List.Item>
                  {' '}
                  g) pages you visited before or after navigating to our
                  website.
                </List.Item>
              </List.List>
            </List.Item>
          </List>
          <Divider />
          <Container>
            <Header>Information We Collect from Third Parties</Header>
            <p>
              We may collect information that other users provide about you when
              they use our services. For example, if you login with your Google
              or Facebook Account, we may use your name and photo for for your
              profile. We may also obtain information from other companies that
              are owned or operated by us, or any other third-party sources, and
              combine that with the information we collect through our services.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>How We Use Information</Header>
            What do we do with the information we collect? The short answer is:
            Provide you with an amazing set of products and services that we
            relentlessly improve. Here are some of the ways we do that:
            <List bulleted>
              <List.Item>
                develop, operate, improve, deliver, maintain, and protect our
                products and services.
              </List.Item>{' '}
              <List.Item>
                send you communications, including by email. For example, we may
                use email to respond to support inquiries or to share
                information about our products, services, and promotional offers
                that we think may interest you.{' '}
              </List.Item>{' '}
              <List.Item>monitor and analyze trends and usage.</List.Item>{' '}
              <List.Item>
                {' '}
                personalize the services by, among other things, suggesting
                friends or profile information, or customizing the content we
                show you, including ads.{' '}
              </List.Item>{' '}
              <List.Item>
                contextualize your experience by, among other things, tagging
                your Memories content using your precise location data (if, of
                course, you’ve consented to us collecting that data) and
                applying other labels based on the content.
              </List.Item>{' '}
              <List.Item>
                {' '}
                improve ad targeting and measurement, including through the use
                of your precise location data (again, if you’ve consented to us
                collecting that data).{' '}
              </List.Item>{' '}
              <List.Item>
                enhance the safety and security of our products and services.
              </List.Item>{' '}
              <List.Item>
                verify your identity and prevent fraud or other unauthorized or
                illegal activity.{' '}
              </List.Item>{' '}
              <List.Item>
                use information we’ve collected from cookies and other
                technology to enhance the services and your experience with
                them.{' '}
              </List.Item>{' '}
              <List.Item>
                enforce our Terms of Service and other usage policies.
              </List.Item>{' '}
            </List>
            <p>
              We may also store some information locally on your device. For
              example, we may store information as local cache so that you can
              open the app and view content faster.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>How We Share Information</Header>
            <p>
              We may share information about you in the following ways: With
              other School Pass Live! users. We may share the following
              information with other users:
            </p>
            <List bulleted>
              <List.Item>
                information about you, such as your name, and pass information.
                information about how you have interacted with the services,
                such as your pass history, and other information that will help
                others using the services. For example, because it may not be
                clear whether a new message request comes from someone you
                actually know, we may share whether you and the requestor have
                School Pass Live! friends in common.
              </List.Item>
              <List.Item>
                any additional information you have consented for us to share.
                For example, when you let us access your device phonebook, we
                may share information about you with other users who have your
                phone number in their device phonebook.{' '}
              </List.Item>
              <List.Item>
                pass information will be shared with other School Pass Live!
                users; For example, a Pass may be sent to just a single teacher,
                but your content may be seen by any Principal or Educator.
              </List.Item>
            </List>
            <p>
              <strong>
                With all School Pass Live! users!, our business partners, and
                the general public.
              </strong>{' '}
              We may share the following information with all School Pass Live!
              users as well as with our business partners and the general
              public: public information like your name, and school population.
            </p>
            <List bulleted>
              <List.Item>
                {' '}
                <strong>With our affiliates.</strong>
                We may share information with entities within the School Pass
                Live!. family of companies.{' '}
              </List.Item>
              <List.Item>
                {' '}
                <strong>With third parties. </strong>
                We may share your information with the following third parties:
                With service providers, sellers, and partners. We may share
                information about you with service providers who perform
                services on our behalf, sellers that provide goods through our
                services, and business partners that provide services and
                functionality.
              </List.Item>
              <List.Item>
                {' '}
                <strong>With third parties for legal reasons.</strong>
                We may share information about you if we reasonably believe that
                disclosing the information is needed to: comply with any valid
                legal process, governmental request, or applicable law, rule, or
                regulation.{' '}
              </List.Item>
              <List.Item>
                investigate, remedy, or enforce potential Terms of Service
                violations.{' '}
              </List.Item>
              <List.Item>
                protect the rights, property, and safety of us, our users, or
                others.{' '}
              </List.Item>
              <List.Item>
                detect and resolve any fraud or security concerns.{' '}
              </List.Item>
              <List.Item>
                {' '}
                <strong>
                  With third parties as part of a merger or acquisition.
                </strong>
                If School Pass Live! gets involved in a merger, asset sale,
                financing, liquidation or bankruptcy, or acquisition of all or
                some portion of our business to another company, we may share
                your information with that company before and after the
                transaction closes.
              </List.Item>
            </List>
            <p>
              We may also share with third parties—such as
              advertisers—aggregated, non-personally identifiable, or
              de-identified information.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>Third-Party Content and Integrations</Header>{' '}
            <p>
              The services may also contain third-party links and search
              results, include third-party integrations, or offer a co-branded
              or third-party-branded service. Through these links, third-party
              integrations, and co-branded or third-party-branded services, you
              may be providing information (including personal information)
              directly to the third party, us, or both. You acknowledge and
              agree that we are not responsible for how those third parties
              collect or use your information. As always, we encourage you to
              review the privacy policies of every third-party website or
              service that you visit or use, including those third parties you
              interact with through our services.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>
              Analytics and Advertising Services Provided by Others
            </Header>
            <p>
              We may let other companies use cookies, web beacons, and similar
              tracking technologies on our services. These companies may collect
              information about how you use our services and other websites and
              online services over time and across different services. This
              information may be used to, among other things, analyze and track
              data, and better understand your online activity.{' '}
            </p>
            <p>
              Additionally, some companies may use information collected on our
              services to deliver targeted advertisements on behalf of us or
              other companies, including on third-party websites and apps.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>How Long We Keep Your Content</Header>{' '}
            <p>
              School Pass Live! lets you capture your pass activity. This
              content is saved for you to view. For example, you can view your
              entire Pass History for a teacher or school.{' '}
            </p>
            <p>
              Finally—and this is important—you should understand that users who
              see the content you provide can always save it using any number of
              techniques: screenshots, in-app functionality, or any other
              image-capture technology. It’s also possible, as with any digital
              information, that someone might be able to access messages
              forensically or find them in a device’s temporary storage. Keep in
              mind that, while our systems are designed to carry out our
              deletion practices automatically, we cannot promise that deletion
              will occur within a specific timeframe. And we may need to suspend
              those deletion practices if we receive valid legal process asking
              us to preserve content or if we receive reports of abuse or other
              Terms of Service violations. Finally, we may also retain certain
              information in backup for a limited period of time or as required
              by law.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>Control over Your Information</Header>
            <p>
              We want you to be in control of your information, so we provide
              you with the following tools.
            </p>
            <List bulleted>
              <List.Item>
                <strong>Access and Updates.</strong> Because your privacy is
                important to us, we may ask you to verify your identity or
                provide additional information before we let you access or
                update your personal information.{' '}
              </List.Item>
              <List.Item>
                <strong>Account Deletion.</strong> While we hope you’ll remain a
                lifelong School Pass Live! User, if for some reason you ever
                want to delete your account, just send an email to
                <a href='mailto:help@schoolpasslive.com'>
                  {' '}
                  help@schoolpasslive.com
                </a>{' '}
              </List.Item>
              <List.Item>
                <strong>Advertising Preferences.</strong> We try to show you ads
                that we think will be relevant to your interests.
              </List.Item>
            </List>
            <p>
              Communicating with other School Pass Live! Users. It’s also
              important to us that you stay in control over whom you communicate
              with using messages.
            </p>
          </Container>
          <Divider />
          <Container>
            <Header>Revisions to the Privacy Policy</Header>
            <p>
              We may change this Privacy Policy from time to time. But when we
              do, we’ll let you know one way or another. Sometimes, we’ll let
              you know by revising the date at the top of the Privacy Policy
              that’s available on our website and mobile application. Other
              times, we may provide you with additional notice (such as adding a
              statement to our websites’ homepages or providing you with an
              in-app notification).
            </p>
          </Container>
        </Segment>
      </Grid.Column>
      <Grid.Column width={6}>
        <ContactInfo />
        <FollowUs />
        <AppStoreLogosSidebar />
        <MeetTheTeam showImages={true} />
        <Divider />
        <Image src='/assets/phonePreview/requestPermissionL.png' size='huge' />
        <Image src='/assets/phonePreview/passHistoryL.png' size='huge' />
        <Image src='/assets/phonePreview/menuClassPassL.png' size='huge' />
      </Grid.Column>
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default PrivacyPolicy;

// Get Users From Firebase
export const userDetailedQuery = ({ auth, uid }) => {
  // Load other user UID, if click on their Profile
  if (uid !== null) {
    return [
      {
        collection: 'users',
        doc: uid,
        storeAs: 'profile'
      }
    ];
  }
  // Get My User Info
  else {
    return [
      {
        collection: 'users',
        doc: auth.uid
      }
    ];
  }
};

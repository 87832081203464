import React, { Component, Fragment } from 'react';
import { Segment, Item, Icon, Button, Image, Label } from 'semantic-ui-react';
// import EventListAtendee from './EventListAtendee';
import { Link } from 'react-router-dom';
import { format, differenceInHours, differenceInMinutes } from 'date-fns';
import PassDetailedMap from '../PassDetailed/PassDetailedMap';

class PassListItem extends Component {
  constructor(props) {
    super(props);
    this.state = { showMap: false };
  }

  handleToggleMap = () => {
    this.setState({
      showMap: !this.state.showMap
    });
  };

  state = {
    latlng: {
      lat: 25.852687,
      lng: -80.371362
    }
  };

  render() {
    const { event } = this.props;
    const { showMap } = this.state;
    const endTime = event.passEnd && format(event.passEnd.toDate(), 'h:mm a');

    // Get Duration To Show
    const durationHours =
      event.passStart &&
      event.passEnd &&
      differenceInHours(event.passEnd.toDate(), event.passStart.toDate());
    const durationMin =
      event.passStart &&
      event.passEnd &&
      differenceInMinutes(event.passEnd.toDate(), event.passStart.toDate());
    const duration = durationHours
      ? `${durationHours} hr ${durationMin - durationHours * 60} min`
      : `${durationMin} min`;

    const coords = event.departLatLng.lat
      ? { lat: `${event.departLatLng.lat}`, lng: `${event.departLatLng.lat}` }
      : {
          lat: 25.852687,
          lng: -80.371362
        };
    // TODO - Change to get from event
    // const passRoute = event.passRoute;
    const passRoute = [
      {
        lat: 25.852687,
        lng: -80.371362
      },
      {
        lat: 25.852841,
        lng: -80.372069
      },
      {
        lat: 25.852901,
        lng: -80.37167
      },
      {
        lat: 25.852559,
        lng: -80.372396
      }
    ];

    return (
      <Segment.Group>
        <Segment
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardLong.jpg'})`,
            padding: '30px'
          }}
        >
          <Item.Group>
            <Item>
              <Item.Image
                size='tiny'
                circular
                src={`/assets/categoryImages/${event.category}.png`}
              />
              <Item.Content>
                <Item.Header as={Link} to={`/profile/${event.studentUID}`}>
                  <span style={{ color: 'yellow' }}>{event.studentName}</span>
                </Item.Header>
                <Item.Description>
                  <span style={{ color: 'white' }}>From:</span>{' '}
                  <em>
                    <Link to={`/passes/${event.id}`}>
                      <span style={{ color: 'cyan' }}>{event.departFrom}</span>
                    </Link>
                  </em>
                </Item.Description>
                <Item.Description>
                  <span style={{ color: 'white' }}>To:</span>{' '}
                  <em>
                    <Link to={`/passes/${event.id}`}>
                      <span style={{ color: 'cyan' }}>
                        {' '}
                        {event.destination}
                      </span>
                    </Link>
                  </em>
                </Item.Description>
                <Item.Description>
                  <span>
                    <Icon name='clock' inverted />
                    <span style={{ color: 'cyan' }}>
                      {format(event.passStart.toDate(), 'h:mm a')}{' '}
                      {endTime ? `- ${endTime} ` : ''} |{' '}
                      <Icon name='calendar alternate outline' inverted />
                      {format(event.passStart.toDate(), 'EEE LLL do yyyy')}
                    </span>
                  </span>
                </Item.Description>
              </Item.Content>
              <Image
                as={Link}
                to={`/profile/${event.studentUID}`}
                size='tiny'
                circular
                src={event.studentPhotoURL}
              />
            </Item>
          </Item.Group>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
        <Segment clearing>
          <Label
            style={{ top: '-80px' }}
            ribbon='right'
            color='red'
            content={`Duration: ${duration}`}
          />{' '}
          <span>
            {showMap && (
              <Fragment>
                <PassDetailedMap
                  key={event.id}
                  title={`Pass Route: ${event.studentName}`}
                  msgToShow={`From ${event.departFrom} to ${
                    event.destination
                  } (Duration: ${duration})`}
                  latlng={coords}
                  passRoute={passRoute}
                  showSatellite={true}
                  minZoom={18}
                  maxZoom={21}
                  showMarker={true}
                  markerName={event.studentName}
                />
              </Fragment>
            )}
            Comments/Reason:{' '}
            {event && event.comments && event.comments !== ''
              ? event.comments
              : 'None'}
          </span>
          <Button
            onClick={this.handleToggleMap}
            // as={Link}
            // to={`/passes/${event.id}`}
            color={showMap ? 'red' : 'teal'}
            floated='right'
            content={showMap ? 'Hide Map' : 'View Map'}
          />
        </Segment>
      </Segment.Group>
    );
  }
}

export default PassListItem;

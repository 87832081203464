import React, { Fragment } from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';

const ClassroomsList = ({ itemsPerRow, maxToShow }) => {
  return (
    <Fragment>
      <Card.Group itemsPerRow={itemsPerRow || 5} centered>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>102</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Gant
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>104</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Weaver
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>210</Card.Header>
            <Card.Meta>
              <span className='date'>Johnson</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Weaver
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>214</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Bell
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>104</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Weaver
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>313</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Goode
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>314</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Jackson
            </Card.Description>
          </Card.Content>
        </Card>
        <Card>
          <Image src={`/assets/categoryImages/classroomChalkboard.jpg`} />
          <Card.Content>
            <Card.Header>104</Card.Header>
            <Card.Meta>
              <span className='date'>Teacher</span>
            </Card.Meta>
            <Card.Description>
              <Icon name='user' size='small' />
              Weaver
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
    </Fragment>
  );
};

export default ClassroomsList;

import React, { Component, createRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

class CropperInput extends Component {
  cropper = createRef();

  cropImage = () => {
    const { setImage } = this.props;

    if (typeof this.cropper.current.getCroppedCanvas() === 'undefined') {
      return;
    }

    // Create Blog for Firestore
    this.cropper.current.getCroppedCanvas().toBlob(blob => {
      setImage(blob);
    }, 'image/jpeg');
  };

  render() {
    const { imagePreview } = this.props;
    return (
      <Cropper
        ref={this.cropper}
        src={imagePreview}
        style={{ height: 200, width: '100%' }}
        // Cropper.js options
        preview='.img-preview'
        aspectRatio={1} // vs. 16 / 9
        viewMode={1} // dont allow user to  crop outside image loaded
        dragMode='move'
        guides={false}
        scalable={false}
        cropBoxMovable={true}
        cropBoxResizable={true}
        crop={this.cropImage}
      />
    );
  }
}

export default CropperInput;

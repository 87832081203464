import {createReducer} from '../../app/common/util/readucerUtils'
import { SIGN_OUT_USER, LOGIN_USER } from './authConstants';

const initialState = {
    isLoggedIn: false,
    currentUser: null
}


const loginUser = (state, payload) => {
    return {
        isLoggedIn: true, 
        currentUser: payload.credential.email
    }
}

const signOutUser = () => {
    return {
        isLoggedIn: false,
        currentUser: null
    }
}

export default createReducer(initialState, {
    [LOGIN_USER]: loginUser,
    [SIGN_OUT_USER]: signOutUser
})
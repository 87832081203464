import React from 'react';
import { Grid } from 'semantic-ui-react';
import SettingsNav from './SettingsNav';
import { connect } from 'react-redux';
import MyInfoPage from './MyInfoPage';
import { Route, Redirect, Switch } from 'react-router-dom';
import PhotosPage from './Photos/PhotosPage';
import MySchoolPage from './MySchoolPage';
import { updatePassword } from '../../auth/authActions';
import { updateProfile } from '../../user/userActions';
import Footer from '../../nav/NavBar/Footer';

const actions = {
  updatePassword,
  updateProfile
};

const mapState = state => ({
  providerId: state.firebase.auth.providerData[0].providerId,
  user: state.firebase.profile
});

const SettingsDashboard = ({
  updatePassword,
  providerId,
  user,
  updateProfile
}) => {
  return (
    <Grid>
      <Grid.Column width={12}>
        {/* Switch stops infinite loop calling state for all settings in Redirect
         https://reacttraining.com/react-router/web/api/Switch */}
        <Switch>
          <Redirect exact from='/settings' to='/settings/about' />
          <Route
            path='/settings/about'
            render={() => (
              <MyInfoPage initialValues={user} updateProfile={updateProfile} />
            )}
          />
          <Route
            path='/settings/school'
            render={() => (
              <MySchoolPage
                initialValues={user}
                updateProfile={updateProfile}
              />
            )}
          />
          <Route path='/settings/photos' component={PhotosPage} />
          <Route
            path='/settings/account'
            render={() => (
              <MySchoolPage
                updatePassword={updatePassword}
                providerId={providerId}
              />
            )}
          />
        </Switch>
      </Grid.Column>
      <Grid.Column width={4}>
        <SettingsNav />
      </Grid.Column>
      <Grid.Column width={16}>
        <Footer />
      </Grid.Column>
    </Grid>
  );
};

export default connect(
  mapState,
  actions
)(SettingsDashboard);

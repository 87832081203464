import React, { Component, createRef } from 'react';
import {
  Header,
  Icon,
  Divider,
  Grid,
  Button,
  Container
} from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import SchoolInfo from './schoolInfo/SchoolInfo';
import PassDetailedMap from '../event/PassDetailed/PassDetailedMap';
import ClassroomsList from '../user/School/classrooms/ClassroomsList';
import Footer from '../nav/NavBar/Footer';
import { Link } from 'react-router-dom';
import SchoolPeopleList from './people/SchoolPeopleList';

class AlarmsPage extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true,
    latlng: {
      lat: 25.852687,
      lng: -80.371362
    }
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={16}>
          <PassDetailedMap
            key={this.state.latlng.lng}
            latlng={this.state.latlng}
            showSatellite={true}
            zoomLevel={17}
          />
        </Grid.Column>

        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='building' color='red' />
            Classrooms
          </Header>
          <Divider />
          <Container>
            <Button as={Link} to='/classrooms' content='Add' color='teal' />
            <Button
              as={Link}
              to='/classrooms'
              content='View All'
              color='grey'
            />
            <br />
            <br />
            <ClassroomsList />
            <br />
          </Container>
          {/* <SimpleMap
            key={this.state.latlng.lng}
            latlng={this.state.latlng}
          /> */}
        </Grid.Column>
        <Grid.Column width={6}>
          <SchoolInfo />
        </Grid.Column>
        <Grid.Column width={10}>
          {/* <Loader active={loading} /> */}
        </Grid.Column>
        <Grid.Column width={16}>
          <Header size='huge'>
            <Icon name='building' color='red' />
            Teachers
          </Header>
          <SchoolPeopleList />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}
export default AlarmsPage;

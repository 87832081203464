import React, { Fragment } from 'react';
import {
  Segment, Header, Icon, Item, Label, Divider,
  // Sticky
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const SchoolInfo = ({contextRef}) => {
  return (
    // <Sticky context={contextRef} offset={100} styleElement={{ zIndex: 0 }}>
      <Fragment>
        <Segment
          textAlign='center'
          style={{ border: 'none' }}
          attached='top'
          inverted
        >
          <Header>MY SCHOOL</Header>
        </Segment>
        <Segment attached>
          <Item>
            <Item.Content>
              <Item.Header>
                <Header as='h2' icon>
                  <Icon name='building' color='teal' />
                  Ronald Reagan/Doral Sr. High School
                  <Header.Subheader>District: Dade</Header.Subheader>
                </Header>
              </Item.Header>
              <Item.Description>
                <Label color='red'>
                  <Icon name='mail square' />
                  School Address
                </Label>
                <br />
                <Link to='/mySchool'>8700 NW 107th Ave, Doral, FL 33017</Link>
              </Item.Description>{' '}
              <Divider />
              <Item.Meta>
                <Label color='red'>
                  <Icon name='phone' />
                  Phone
                </Label>
                <br />
                305-805-1900
              </Item.Meta>
            </Item.Content>
          </Item>
        </Segment>
      </Fragment>
    // </Sticky>
  );
};

export default SchoolInfo;

import React, { Component, createRef } from 'react';
import { Grid, Header, Icon, Feed, Segment } from 'semantic-ui-react';
import PassDetailedMap from '../PassDetailed/PassDetailedMap';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import Footer from '../../nav/NavBar/Footer';
import { connect } from 'react-redux';
import { getPassesForDashboard } from '../eventActions';
// import PassActivity from '../PassActivity/PassActivity';
import {
  firestoreConnect
  // isLoaded
} from 'react-redux-firebase';
import SchoolInfo from '../../school/schoolInfo/SchoolInfo';
// import { Link } from 'react-router-dom';

const query = [
  {
    collection: 'activity',
    orderBy: ['timestamp', 'desc'],
    limit: 5
  }
];

const mapState = state => ({
  // CHANGE TO DIRECT FIRESTORE vs REDUX: https://www.udemy.com/build-an-app-with-react-redux-and-firestore-from-scratch/learn/lecture/10200830#content
  passes: state.passes.passes, // redux firestore state.firestore.ordered.passes vs. direct from firestore
  loading: state.async.loading,
  activities: state.firestore.ordered.activity
});

const actions = {
  // createEvent,
  // updateEvent,
  // deleteEvent
  getPassesForDashboard
};

class PassMapLive extends Component {
  contextRef = createRef();

  state = {
    latlng: {
      lat: 25.852687,
      lng: -80.371362
    },
    morePasses: false,
    loadingInitial: true,
    loadedPasses: []
  };

  async componentDidMount() {
    let next = await this.props.getPassesForDashboard();

    if (next && next.docs && next.docs.length > 1) {
      this.setState({
        morePasses: true,
        loadingInitial: false
      });
    }
  }

  // When component updates, update our loaded pass
  componentDidUpdate(prevProps) {
    if (this.props.passes !== prevProps.passes) {
      this.setState({
        loadedPasses: [...this.state.loadedPasses, ...this.props.passes]
      });
    }
  }

  getNextPasses = async () => {
    const { passes } = this.props;
    let lastPass = passes && passes[passes.length - 1];

    let next = await this.props.getPassesForDashboard(lastPass);

    if (next && next.docs && next.docs.length <= 1) {
      this.setState({
        morePasses: false
      });
    }
  };

  render() {
    //  const { loading, activities } = this.props;
    //  const { morePasses, loadedPasses } = this.state;
    if (this.state.loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={12}>
          <Header size='huge'>
            <Icon name='eye' color='red' />
            Current Students Out of Class
          </Header>
          <PassDetailedMap
            key={this.state.latlng.lng}
            latlng={this.state.latlng}
            showSatellite={true}
            zoomLevel={17}
            isLivePassMap={true}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          {/* <PassActivity
                     activities={activities}
                     contextRef={this.contextRef}
                   /> */}
          <Segment
            textAlign='center'
            style={{ border: 'none' }}
            attached='top'
            inverted
          >
            <Header>NEW PASSES</Header>
          </Segment>
          <Segment attached textAlign='center'>
            <Feed>
              <Feed.Event>
                <Feed.Label>
                  <img src='/assets/user.png' alt='' />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Summary>
                    New Pass! <Feed.User>Londyn Sanders</Feed.User> is leaving
                    319 going to Office
                  </Feed.Summary>
                  <Feed.Meta>
                    <Feed.Date>2 min ago</Feed.Date>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
              <Feed.Event>
                <Feed.Label>
                  <img src='/assets/user.png' alt='' />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Summary>
                    New Pass! <Feed.User>Daffy Duck</Feed.User> is leaving 365
                    to go to 217
                  </Feed.Summary>
                  <Feed.Meta>
                    <Feed.Date>10 min ago</Feed.Date>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
              <Feed.Event>
                <Feed.Label>
                  <img src='/assets/user.png' alt='' />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Summary>
                    New Pass! <Feed.User>Londyn Sanders</Feed.User> is leaving{' '}
                  </Feed.Summary>
                  <Feed.Meta>
                    <Feed.Date>14 min ago</Feed.Date>
                  </Feed.Meta>
                </Feed.Content>
              </Feed.Event>
            </Feed>
          </Segment>
          <SchoolInfo />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  mapState,
  actions
)(firestoreConnect(query)(PassMapLive));

import React, { Component, Fragment } from "react";
import PassDashboard from "../../features/event/PassDashboard/PassDashboard";
import NavBar from "../../features/nav/NavBar/NavBar";
import { Container } from "semantic-ui-react";
import { Route, Switch, withRouter } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import Classrooms from "../../features/user/School/Classrooms";
import PassForm from "../../features/event/PassForm/PassForm";
import PassDetailedPage from "../../features/event/PassDetailed/PassDetailedPage";
import ExtraCreditShop from "../../features/extraCreditShop/ExtraCreditShop";
import PeopleDashboard from "../../features/user/School/PeopleDashboard";
import UserDetailedPage from "../../features/user/UserDetailed/UserDetailedPage";
import SettingsDashboard from "../../features/user/Settings/SettingsDashboard";
import TestComponent from "../../features/testArea/TestComponent";
import ModalManager from "../../features/modals/ModalManager";
import { UserIsAuthenticated } from "../../features/auth/authWrapper";
import NotFound from "./NotFound";
import SchoolPage from "../../features/school/SchoolPage";
import AboutInfo from "../../features/about/AboutInfo";
import PassMapLive from "../../features/event/PassMapLive/PassMapLive";
import AlarmsPage from "../../features/school/AlarmsPage";
import PassRequest from "../../features/event/PassRequest";
import AskJamie from "../../features/about/contactus/AskJamie";
import ContactPage from "../../features/about/contactus/ContactPage";
import TeamMember from "../../features/about/team/TeamMember";
import FAQ from "../../features/about/FAQ";
import PrivacyPolicy from "../../features/about/PrivacyPolicy";
import TermsService from "../../features/about/TermsService";
import AppStore from "../../features/about/appStore/AppStore";
import OurStory from "../../features/about/team/OurStory";
import ExamplePass from "../../features/about/ExamplePass";
import ClassPassPage from "../../features/about/ClassPassPage";
import SchoolsDistricts from "../../features/about/personas/DistrictsInfoVisitor";
import TeachersInfoVisitor from "../../features/about/personas/TeachersInfoVisitor";
import SchoolsInfoVisitor from "../../features/about/personas/SchoolsInfoVisitor";
import InfoNeededForm from "../../features/user/register/InfoNeededForm";

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        {/* EXTERNAL LINKS */}
        <Route
          path='/ganttech'
          component={() => {
            window.location.href = "http://ganttech.com/";
            return null;
          }}
        />
        <Route
          path='/dadeschools'
          component={() => {
            window.location.href = "http://www.dadeschools.net/";
            return null;
          }}
        />
        <Route
          path='/fiu'
          component={() => {
            window.location.href =
              "https://www.cis.fiu.edu/ultimate-software-academy-for-computer-science-education-fiu/";
            return null;
          }}
        />{" "}
        <Route
          path='/stonemanDouglas'
          component={() => {
            window.location.href =
              "https://www.browardschools.com/stonemandouglas";
            return null;
          }}
        />
        <Route
          path='/reaganDoral'
          component={() => {
            window.location.href =
              "https://www.ronaldreaganseniorhighschool.com/";
            return null;
          }}
        />
        <Route
          path='/facebook'
          component={() => {
            window.location.href = "https://www.linkedin.com/in/ganttech/";
            return null;
          }}
        />
        <Route
          path='/instagram'
          component={() => {
            window.location.href = "https://www.linkedin.com/in/ganttech/";
            return null;
          }}
        />
        <Route
          path='/twitter'
          component={() => {
            window.location.href = "https://www.linkedin.com/in/ganttech/";
            return null;
          }}
        />
        <Route
          path='/youtube'
          component={() => {
            window.location.href =
              "https://www.youtube.com/channel/UCMHEIMOiZOyHt8VWwJ52x6g?view_as=subscriber";
            return null;
          }}
        />
        <Route
          path='/linkedIn'
          component={() => {
            window.location.href = "https://www.linkedin.com/in/ganttech/";
            return null;
          }}
        />
        <Route exact path='/' component={HomePage} />
        {/* INTERNAL LINKS */}
        <Route
          path='/(.+)'
          render={() => (
            <Fragment>
              <NavBar />
              <Container className='main'>
                <Switch key={this.props.location.key}>
                  <Route exact path='/register' component={InfoNeededForm} />
                  <Route exact path='/about/info' component={AboutInfo} />
                  <Route exact path='/about/ourStory' component={OurStory} />
                  <Route path='/info/schools' component={SchoolsInfoVisitor} />
                  <Route path='/info/districts' component={SchoolsDistricts} />
                  <Route
                    path='/info/teachers'
                    component={TeachersInfoVisitor}
                  />
                  <Route exact path='/examplePass' component={ExamplePass} />
                  <Route path='/about/appPlayStore' component={AppStore} />
                  <Route path='/info/faq' component={FAQ} />
                  <Route path='/info/privacyPolicy' component={PrivacyPolicy} />
                  <Route path='/info/termsService' component={TermsService} />
                  <Route path='/askJamie' component={AskJamie} />
                  <Route path='/mySchool' component={SchoolPage} />
                  <Route path='/contact/:type' component={ContactPage} />
                  <Route path='/team/:member' component={TeamMember} />
                  <Route path='/alarms' component={AlarmsPage} />
                  <Route
                    path='/classrooms'
                    component={UserIsAuthenticated(Classrooms)}
                  />
                  <Route path='/extraCreditShop' component={ExtraCreditShop} />
                  <Route
                    exact
                    path='/passes'
                    component={UserIsAuthenticated(PassDashboard)}
                  />
                  <Route
                    exact
                    path='/sharedClassPass'
                    component={UserIsAuthenticated(ClassPassPage)}
                  />
                  <Route exact path='/passesRequests' component={PassRequest} />
                  <Route
                    path='/passes/:id'
                    component={UserIsAuthenticated(PassDetailedPage)}
                  />
                  <Route
                    path='/passesRequests/:id'
                    component={UserIsAuthenticated(PassDetailedPage)}
                  />
                  <Route
                    path='/studentsOutOfClass'
                    component={UserIsAuthenticated(PassMapLive)}
                  />
                  <Route
                    path='/people'
                    component={UserIsAuthenticated(PeopleDashboard)}
                  />
                  <Route
                    path='/profile/:id'
                    component={UserIsAuthenticated(UserDetailedPage)}
                  />
                  <Route
                    path='/settings'
                    component={UserIsAuthenticated(SettingsDashboard)}
                  />
                  <Route
                    path={["/createPass", "/manage/:id"]}
                    component={UserIsAuthenticated(PassForm)}
                  />
                  <Route path='/test' component={TestComponent} />
                  <Route component={NotFound} />
                </Switch>
              </Container>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

export default withRouter(App); // withRouter(App);

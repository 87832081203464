import React, { Fragment } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';

const UserPhotos = ({ profile }) => {
  return (
    <Fragment>
      <Card.Group itemsPerRow={2} centered>
        <Card>
          <Image src={profile.photoURL || '/assets/user.png'} />
          <Button positive>Selfie</Button>
        </Card>
      </Card.Group>
    </Fragment>
  );
};

export default UserPhotos;

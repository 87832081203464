import React, { Fragment, Component } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { openModal } from '../../modals/modalActions';
// import { Button } from 'semantic-ui-react';

const mapState = state => ({
  isLoggedIn: !state.firebase.auth.isEmpty
});

const actions = {
  openModal
};

class Footer extends Component {
  handleSignin = () => {
    this.props.openModal('LoginModal');
  };
  handleRegister = () => {
    this.props.openModal('RegisterModal');
  };

  render() {
    let now = format(Date.now(), 'yyyy');

    const { isLoggedIn } = this.props;
    return (
      <Fragment>
        {isLoggedIn ? (
          <Fragment>
            {now} © School Pass Live! Inc. All Rights Reserved. <br />
            <Link to='/'>Get on iOS</Link> |{' '}
            <a href='https://play.google.com/store/apps/details?id=com.schoolpasslive.jamiegant'>
              Get on Android
            </a>{' '}
            | <Link to='/info/faq'>Frequently Asked Questions</Link> |{' '}
            <Link to='/about/ourStory'>Our Story</Link> |{' '}
            <Link to='/info/districts'>School Districts</Link> |{' '}
            <Link to='/info/privacyPolicy'>Privacy Policy</Link> |{' '}
            <Link to='/info/termsService'>Terms & Service</Link>
          </Fragment>
        ) : (
          <Fragment>
            {/* <Button
              onClick={this.handleRegister}
              color='teal'
              content='Register'
              style={{ marginLeft: "0.5em" }}
            />{" "}
            <Button onClick={this.handleSignin} color='red' content='Login' />{" "}
            <br /> */}
            {now} © School Pass Live! Inc. All Rights Reserved. <br />
            <Link to='/'>Get on iOS</Link> |{' '}
            <a href='https://play.google.com/store/apps/details?id=com.schoolpasslive.jamiegant'>
              Get on Android
            </a>{' '}
            | <Link to='/about/ourStory'>Our Story</Link> |{' '}
            <Link to='/info/districts'>School Districts</Link> |{' '}
            <Link to='/info/schools'>Schools</Link> |{' '}
            <Link to='/info/teachers'>Teachers</Link> |{' '}
            <Link to='/info/faq'>FAQ</Link> |{' '}
            <Link to='/contact/form'>Contact</Link>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default connect(
  mapState,
  actions
)(Footer);

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBJQHxtQqaNQrCs2725EmBclVhSxLcrjpc',
  authDomain: 'school-live-2020.firebaseapp.com',
  databaseURL: 'https://school-live-2020.firebaseio.com',
  projectId: 'school-live-2020',
  storageBucket: 'school-live-2020.appspot.com',
  messagingSenderId: '1014510749179',
  appId: '1:1014510749179:web:b6a06abfe8482367'
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;

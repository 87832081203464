import React from 'react';
import { Segment, Header, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';

const ClassPass = () => {
  return (
    <Segment.Group>
      <Segment
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${chalkboard})`,
          padding: '30px'
        }}
      >
        <Header inverted color='yellow'>
          * CLASS PASS MODE{' '}
          <span className='chalkboardGreenTitle'>
            Using shared device or No Device
          </span>{' '}
        </Header>
        <Image floated='left' src='/assets/phonePreview/menuClassPass.png' />
        <span className='chalkboardPinkTitle'>NO CELL PHONE - NO PROBLEM</span>
        <p>
          School Pass Live!™ can be applied in every class room. Not every
          student has a cellphone/mobile device so School Districts or Teachers
          can use any mobile device with School Pass Live! as a shared device
          for classrooms. pass.
        </p>{' '}
        <p>
          Teachers can also use this website to have students request passes to
          leave their clasroom without taking a device with them (
          <span className='chalkboardOrange'>
            No real-time monitoring without a device)
          </span>
          .
        </p>
        <p>
          In{' '}
          <Link to='/sharedClassPass'>
            <span className='chalkboardCyanTitle'>Class Pass Mode</span>
          </Link>{' '}
          mode, the device can be used by students to monitor them when they
          leave the class. Many teachers have old cellphones or tablets that
          they no longer use. Now you can recycle them, and use them in{' '}
          <Link to='/sharedClassPass'>
            <span className='chalkboardCyanTitle'>class pass mode</span>
          </Link>
          .
        </p>
      </Segment>
      <Segment
        textAlign='center'
        inverted
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
        }}
      />
    </Segment.Group>
  );
};

export default ClassPass;

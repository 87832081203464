import React, { Component } from 'react';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Image,
  Container,
  Form,
  Button,
  Icon
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ContactInfo from './ContactInfo';
import { addComment } from '../../user/userActions';
import TextArea from '../../../app/common/form/TextArea';
import { combineValidators, isRequired } from 'revalidate';
import FollowUs from '../FollowUs';
import Footer from '../../nav/NavBar/Footer';

import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';

const validate = combineValidators({
  comment: isRequired('comment')
});

const mapState = state => ({
  profile: state.firebase.profile
});

const actions = {
  addComment
};

class AskJamie extends Component {
  onFormSubmit = values => {
    const { addComment, reset } = this.props;
    let path = '/comments/askJamie';
    let isLoggedIn = true;
    addComment(path, values, isLoggedIn);
    reset();
  };

  render() {
    const { pristine, submitting, profile, handleSubmit } = this.props;
    let firstName;
    if (String(profile.displayName).split(' ').length > 0) {
      firstName = String(profile.displayName).split(' ')[0];
    } else {
      firstName = profile.displayName;
    }
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='comments outline' color='red' />
            Ask/Tell Jamie
          </Header>
          <Divider />
          <Segment.Group>
            <Segment
              textAlign='center'
              inverted
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${chalkboard})`,
                padding: '30px'
              }}
            >
              <Container>
                <Image
                  bordered
                  circular
                  size='small'
                  centered
                  src='/assets/team/askJamieClassroom.jpg'
                />
                Hey{' '}
                <span style={{ color: 'pink' }}>
                  <em>{profile ? ` ${firstName}` : ''}</em>
                </span>
                , I'm Jamie Gant, the teacher who coded this website and both
                apps. I was training teachers including my friend who works at
                Marjory Stoneman Douglas only 4 days before the school shooting
                shooting. In April 2019, my school also received a threat. I
                decided to do this to try to help make all schools safer.
              </Container>
              <br />
              <span style={{ color: 'pink' }}>
                <strong>Send your questions, comments, & feedback</strong>
              </span>
              <br />
              <span style={{ color: 'cyan' }}>
                I'll get back to you soon, right after I'm done grading.
              </span>
              <Divider />
              <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                <Field
                  name='comment'
                  type='text'
                  component={TextArea}
                  rows={2}
                />
                <Button
                  content='Send To Teacher'
                  disabled={submitting || pristine}
                  labelPosition='left'
                  color='red'
                  icon='edit'
                />
              </Form>
            </Segment>
            <Segment
              textAlign='center'
              inverted
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
              }}
            />
          </Segment.Group>
        </Grid.Column>
        <Grid.Column width={6}>
          <ContactInfo />
          <FollowUs />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  mapState,
  actions
)(
  reduxForm({
    form: 'askJamie',
    destroyOnUnmount: true,
    validate
  })(AskJamie)
);

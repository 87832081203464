import React, { Component, Fragment } from 'react';
import { Grid, Header, Segment, Icon, Divider, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
import Testimonials from '../Testimonials';
import FeedbackForm from './FeedbackForm';
import { addComment } from '../../user/userActions';
import FollowUs from '../FollowUs';
import Footer from '../../nav/NavBar/Footer';
import AppStoreLogosSidebar from '../appStore/AppStoreLogosSidebar';

const mapState = (state, ownProps) => ({
  profile: state.firebase.profile,
  formType: ownProps.match.params.type
});

const actions = {
  addComment
};

class ContactPage extends Component {
  render() {
    const { profile, formType, addComment } = this.props;
    const isLoggedIn = !profile.isEmpty;
    const isFeedback = formType === 'feedback';
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='talk' color='red' />
            {isFeedback ? 'Feedback' : 'Contact Us'}
          </Header>
          <Divider />

          {isFeedback ? (
            <FeedbackForm profile={profile} addComment={addComment} />
          ) : (
            <ContactForm />
          )}
        </Grid.Column>
        <Grid.Column width={6}>
          {isLoggedIn ? (
            <Fragment>
              <Label as='h3'>
                <Icon name='thumbs up outline' size='large' /> Social Media
              </Label>
              <Testimonials />
              <Segment
                textAlign='center'
                style={{ border: 'none' }}
                attached='top'
                inverted
              >
                <Header>Latest Feedback</Header>
              </Segment>
              <Segment attached textAlign='center'>
                {' '}
                <Label as='h3'>
                  <Icon name='user' size='large' /> James
                </Label>{' '}
                "Feedback Info Here. is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, "
              </Segment>
            </Fragment>
          ) : (
            <Fragment>
              <ContactInfo />
              <FollowUs />
              <AppStoreLogosSidebar />
            </Fragment>
          )}
        </Grid.Column>

        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  mapState,
  actions
)(ContactPage);

import React, { Component, Fragment } from 'react';
import {
  Button,
  Divider,
  Form,
  Header,
  Segment,
  Container
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan
} from 'revalidate';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import PlaceInput from '../../../app/common/form/PlaceInput';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';
import { addComment } from '../../user/userActions';

const mapState = state => ({
  profile: state.firebase.profile
});

const actions = {
  addComment
};

const validate = combineValidators({
  fullName: isRequired('Please enter your Full Name'),
  email: composeValidators(
    isRequired({ message: 'Please enter your Email' }),
    hasLengthGreaterThan(10)({
      message: 'Email needs to be be at least 10 characters'
    })
  )(),
  city: isRequired('Please select a City'),
  comment: isRequired('comment'),
  howDidHearAboutUs: isRequired('howDidHearAboutUs')
});

const contactOptions = [
  // { key: 'noSelection', text: '-----', value: '' },
  { key: 'internet', text: 'Internet (Google, etc)', value: 'Internet' },
  { key: 'socialMedia', text: 'Social Media', value: 'Social Media' },
  { key: 'wordOfMouth', text: 'Word Of Mouth', value: 'Word Of Mouth' },
  { key: 'other', text: 'Other (please provide)', value: 'Other' }
];

class ContactForm extends Component {
  onFormSubmit = values => {
    const { addComment, reset } = this.props;

    // Add Logged In User Info
    let path = '/comments/visitors';

    addComment(path, values);
    reset();
  };

  render() {
    const { pristine, submitting, profile, handleSubmit } = this.props;
    let isLoggedIn = profile ? !profile.isEmpty : false;

    return (
      <Segment.Group>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${chalkboard})`,
            padding: '25px'
          }}
        >
          <Container>
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Header inverted color='teal'>
                Your Information
              </Header>
              {isLoggedIn && (
                <span className='chalkboardYellowTitle'>
                  {profile.displayName}
                </span>
              )}
              {!isLoggedIn && (
                <Fragment>
                  <Field
                    width={5}
                    name='fullName'
                    autoComplete='off'
                    type='text'
                    component={TextInput}
                    placeholder='Full Name'
                  />
                  <Field
                    width={5}
                    name='email'
                    autoComplete='off'
                    type='text'
                    component={TextInput}
                    placeholder='Email Address'
                  />
                  <Field
                    name='city'
                    placeholder='City'
                    autoComplete='off'
                    options={{ types: ['(cities)'] }}
                    component={PlaceInput}
                    width={8}
                  />
                </Fragment>
              )}
              <Field
                width={5}
                name='phone'
                autoComplete='off'
                type='text'
                component={TextInput}
                placeholder='Phone (Optional)'
              />

              {!isLoggedIn && (
                <Fragment>
                  <Header inverted color='teal'>
                    School
                  </Header>
                  <Field
                    width={5}
                    name='school'
                    autoComplete='off'
                    type='text'
                    component={TextInput}
                    placeholder='School Name (Optional)'
                    value={profile ? profile.schoolName : ''}
                  />
                  <Divider />
                  <Field
                    name='howDidHearAboutUs'
                    component={SelectInput}
                    options={contactOptions}
                    placeholder='How Did You Hear About Us?'
                  />
                </Fragment>
              )}
              {isLoggedIn && (
                <span className='chalkboardYellowTitle'>
                  {profile.schoolName}
                </span>
              )}
              <Field
                name='comment'
                type='text'
                component={TextArea}
                placeholder='Enter your comments'
                rows={2}
              />
              <Divider />
              <Button
                disabled={submitting || pristine}
                content='Send To Teacher'
                labelPosition='left'
                color='red'
                icon='edit'
              />
            </Form>
          </Container>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
      </Segment.Group>
    );
  }
}
export default connect(
  mapState,
  actions
)(
  reduxForm({
    form: 'contactus',
    destroyOnUnmount: true,
    validate,
    Fields: 'comment'
  })(ContactForm)
);

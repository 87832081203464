import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';

const SchoolPeopleList = () => {
  return (
    <Card.Group itemsPerRow={5} centered>
      <Card>
        <Image src='/assets/user.png' />
        <Card.Content>
          <Card.Header>Bunny, B.</Card.Header>
          <Card.Meta>
            <span className='date'>Teacher</span>
          </Card.Meta>
          <Card.Description>
            <Icon name='building' size='large' />
            039
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Image src='/assets/team/familyLisha.jpg' />
        <Card.Content>
          <Card.Header>Gant, A.</Card.Header>
          <Card.Meta>
            <span className='date'>Teacher</span>
          </Card.Meta>
          <Card.Description>
            <Icon name='building' size='large' />
            102
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Image src='/assets/user.png' />
        <Card.Content>
          <Card.Header>Mouse, M.</Card.Header>
          <Card.Meta>
            <span className='date'>Teacher</span>
          </Card.Meta>
          <Card.Description>
            <Icon name='building' size='large' />
            237
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Image src='/assets/user.png' />
        <Card.Content>
          <Card.Header>Gant, W.</Card.Header>
          <Card.Meta>
            <span className='date'>Principal</span>
          </Card.Meta>
          <Card.Description>
            <Icon name='building' size='large' />
            305
          </Card.Description>
        </Card.Content>
      </Card>
      <Card>
        <Image src='/assets/user.png' />
        <Card.Content>
          <Card.Header>Carr, C.</Card.Header>
          <Card.Meta>
            <span className='date'>Security Guard</span>
          </Card.Meta>
          <Card.Description>
            <Icon name='building' size='large' />
            324
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

export default SchoolPeopleList;

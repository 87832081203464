import React, { Component, createRef } from 'react';
import { Grid, Loader, Divider, Icon, Header, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
// import { createEvent, updateEvent } from '../eventActions';
import { getPassRequestsForDashboard } from '../event/eventActions';
import LoadingComponent from '../../app/layout/LoadingComponent';
import {
  firestoreConnect
  // isLoaded
} from 'react-redux-firebase';
import SchoolInfo from '../school/schoolInfo/SchoolInfo';
import Footer from '../nav/NavBar/Footer';
import PassRequestList from './PassRequestList/PassRequestList';
import { deletePassRequest } from '../event/eventActions';

const query = [
  {
    collection: 'activity',
    orderBy: ['timestamp', 'desc'],
    limit: 5
  }
];

const mapState = state => ({
  // CHANGE TO DIRECT FIRESTORE vs REDUX: https://www.udemy.com/build-an-app-with-react-redux-and-firestore-from-scratch/learn/lecture/10200830#content
  passes: state.passes.passes, // redux firestore state.firestore.ordered.passes vs. direct from firestore
  loading: state.async.loading
});

const actions = {
  // createEvent,
  // updateEvent,
  deletePassRequest,
  getPassRequestsForDashboard
};

class PassRequest extends Component {
  contextRef = createRef();

  // handleCreateEvent = newEvent => {
  //   newEvent.id = cuid();
  //   newEvent.hostPhotoURL = '/assets/user.png';
  //   this.props.createEvent(newEvent);
  // };

  // handleUpdateEvent = updatedEvent => {
  //   this.props.updateEvent(updateEvent);
  // };

  handleDeleteRequest= id => {
    this.props.deletePassRequest(id);
      this.props.history.push('/passesRequests/');
  };

  state = {
    morePasses: false,
    loadingInitial: true,
    loadedPasses: []
  };

  async componentDidMount() {
    let next = await this.props.getPassRequestsForDashboard();

    if (next && next.docs && next.docs.length > 1) {
      this.setState({
        morePasses: true,
        loadingInitial: false
      });
    } else {
      this.setState({
        loadingInitial: false
      });
    }
  }

  // When component updates, update our loaded pass
  componentDidUpdate(prevProps) {
    if (this.props.passes !== prevProps.passes) {
      this.setState({
        loadedPasses: [...this.state.loadedPasses, ...this.props.passes]
      });
    }
  }

  getNextPasses = async () => {
    const { passes } = this.props;
    let lastPass = passes && passes[passes.length - 1];

    let next = await this.props.getPassRequestsForDashboard(lastPass);

    if (next && next.docs && next.docs.length <= 1) {
      this.setState({
        morePasses: false
      });
    }
  };

  render() {
    const { loading } = this.props;
    const { morePasses, loadedPasses } = this.state;
    if (this.state.loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='tasks' color='red' />
            Pass Requests
          </Header>
          <Divider />
          <div ref={this.contextRef}>
            <PassRequestList
              passes={loadedPasses}
              loading={loading}
              morePasses={morePasses}
              getNextPasses={this.getNextPasses}
              deletePassRequest={this.handleDeleteRequest}
              // selectEvent={this.handleSelectEvent}
              // deleteEvent={this.handleDeleteEvent}
            />
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <Image src='/assets/phonePreview/classRulesXL.png' />
          <SchoolInfo />
        </Grid.Column>
        <Grid.Column width={10}>
          <Loader active={loading} />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  mapState,
  actions
)(firestoreConnect(query)(PassRequest));

import React, { Component, createRef } from 'react';
import {
  Header,
  Icon,
  Divider,
  Grid,
  Card,
  Image,
  Label,
  Segment,
  Button
} from 'semantic-ui-react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import PassDetailChat from '../../event/PassDetailed/PassDetailChat';
import SchoolPeopleList from '../../school/people/SchoolPeopleList';

class PeopleDashboard extends Component {
  contextRef = createRef();

  state = {
    loadingInitial: true
  };

  componentDidMount() {
    this.setState({
      loadingInitial: false
    });
  }

  render() {
    const { loadingInitial } = this.state;
    if (loadingInitial) return <LoadingComponent inverted={true} />; //if (!isLoaded(passes))
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='users' />
            People
          </Header>
          <Divider />
          <Label>
            <Icon name='mail square' size='big' /> EDUCATORS
          </Label>
          <SchoolPeopleList />
          <Divider />
          <Label>
            <Icon name='mail square' size='big' /> STUDENTS
          </Label>
          <Card.Group itemsPerRow={6} centered>
            <Card>
              <Image src='/assets/user.png' />
              <Card.Content>
                <Card.Header>James</Card.Header>
                <Card.Meta>
                  <span className='date'>Student</span>
                </Card.Meta>
                <Card.Description>
                  <Icon name='address card' size='large' />5
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/assets/user.png' />
              <Card.Content>
                <Card.Header>Londyn</Card.Header>
                <Card.Meta>
                  <span className='date'>Student</span>
                </Card.Meta>
                <Card.Description>
                  <Icon name='address card' size='large' />
                  10
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/assets/user.png' circular />
              <Card.Content>
                <Card.Header>Alicia</Card.Header>
                <Card.Meta>
                  <span className='date'>Teacher</span>
                </Card.Meta>
                <Card.Description>
                  <Icon name='building' size='large' />
                  102
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/assets/user.png' />
              <Card.Content>
                <Card.Header>Khalil</Card.Header>
                <Card.Meta>
                  <span className='date'>Student</span>
                </Card.Meta>
                <Card.Description>
                  <Icon name='address card' size='large' />
                  12
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Image src='/assets/user.png' />
              <Card.Content>
                <Card.Header>Sydney</Card.Header>
                <Card.Meta>
                  <span className='date'>Joined in 2015</span>
                </Card.Meta>
                <Card.Description>Teacher</Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Grid.Column>
        <Grid.Column width={6}>
          <Segment
            textAlign='center'
            style={{ border: 'none' }}
            attached='top'
            color='red'
            inverted
          >
            <Header>School Chat</Header>
          </Segment>
          <Segment attached textAlign='center'>
            {' '}
            Person Name
            <PassDetailChat />
            <Button content='SEND MESSAGE' />
          </Segment>
        </Grid.Column>
        <Grid.Column width={10}>
          {/* <Loader active={loading} /> */}
        </Grid.Column>
      </Grid>
    );
  }
}
export default PeopleDashboard;

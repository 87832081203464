import React, { Fragment } from 'react';
import { Image, Divider } from 'semantic-ui-react';

const Testimonials = () => {
  return (
    <Fragment>{' '}
      <a
        href='https://twitter.com/MarioDB/status/1029481099255316481'
        rel='noopener noreferrer'
        target='_blank'
      >
        <Image centered src='/assets/socialMedia/tweetMarioDiazBalard.jpg' />
      </a>
      <Divider />
    </Fragment>
  );
};

export default Testimonials;

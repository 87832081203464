/*global google*/
import React, { Component } from 'react';
import { Button, Divider, Form, Header, Segment } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import PlaceInput from '../../../app/common/form/PlaceInput';
import { geocodeByAddress } from 'react-places-autocomplete';
import { combineValidators, isRequired } from 'revalidate';
import PassDetailedMap from '../../event/PassDetailed/PassDetailedMap';

const validate = combineValidators({
  schoolName: isRequired('Please select a school Name')
});

class MySchoolPage extends Component {
  state = {
    schoolLatLng: {}
  };

  onFormSubmit = values => {
    // Add Values for LatLng when create Event
    values.schoolCoordinates = this.state.schoolLatLng;

    // Check

    // Update the User information
    this.props.handleSubmit(this.props.updateProfile);
  };

  handleSchoolSelect = selectedSchool => {
    geocodeByAddress(selectedSchool)
      .then(latlng => {
        this.setState({
          schoolLatLng: latlng
        });
      })
      .then(() => {
        this.props.change('schoolName', selectedSchool);
      });
  };

  render() {
    const {
      pristine,
      submitting,
      handleSubmit,
      updateProfile,
      initialValues
    } = this.props;
    return (
      <Segment.Group>
        <Segment
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardLong.jpg'})`,
            padding: '30px'
          }}
        >
          <Header dividing size='large' content='School Information' />
          <Form onSubmit={handleSubmit(updateProfile)}>
            <PassDetailedMap
              lat={
                initialValues.schoolCoordinates
                  ? initialValues.schoolCoordinates.lat
                  : 0.0
              }
              lng={
                initialValues.schoolCoordinates
                  ? initialValues.schoolCoordinates.lon
                  : 0.0
              }
              minZoom={12}
              maxZoom={21}
              showMarker={true}
              markerName={'School'}
            />

            <Divider />
            {/* TODO: REPLACE FIELD WITH SELECT. IMPORT SCHOOLS BASED ON LOCATION JUST LIKE APP */}
            <Field
              name='schoolName'
              component={PlaceInput}
              options={{
                location: new google.maps.LatLng(this.state.cityLatLng),
                radius: 1000,
                types: ['establishment']
              }}
              onSelect={this.handleSchoolSelect}
              placeholder='Enter The School Name and Address'
            />
            <Divider />
            <Button
              disabled={pristine || submitting}
              size='large'
              positive
              content='Update Profile'
            />
          </Form>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
      </Segment.Group>
    );
  }
}

export default reduxForm({
  form: 'userProfile',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(MySchoolPage);

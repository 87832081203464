import React, { Fragment } from 'react';
import { Image, Header, Icon, Divider, Grid } from 'semantic-ui-react';
import Footer from '../../nav/NavBar/Footer';

const Alicia = () => {
  return (
    <Fragment>
      <Image
        bordered
        circular
        size='large'
        centered
        src='/assets/team/familyLisha.jpg'
      />
      <Header size='huge' color='yellow' inverted>
        <Icon name='graduation cap' />
        Alicia
      </Header>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>{' '}
      <p>
        of classical Latin literature from 45 BC, making it over 2000 years old.
        Richard McClintock, a Latin professor at Hampden-Sydney College in
        Virginia, looked up one of the more obscure Latin words, consectetur,
        from a Lorem Ipsum
      </p>
      <br />
      <span style={{ color: 'pink' }}>
        <strong>NEED BIO TO UPDATE THE DUMMY TEXT ABOVE</strong>
      </span>
      <br />
      <span style={{ color: 'cyan' }}>
        REMEMBER TO DO THIS BEFORE LAUNCHING THE WEBSITE
      </span>
      <Divider />
      <Grid divided='vertically'>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Image src='/assets/team/familyTeachersBoard.jpg' />
          </Grid.Column>
          <Grid.Column>
            <Image src='/assets/team/familyTeachersDesk.jpg' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default Alicia;

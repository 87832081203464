/*global google*/
import React from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';

// const Marker = () => <Icon name='id badge' size='big' color='red' />;

// const StudentLocation = () => (
//   <Icon name='street view' size='big' color='red' />
// );

const StudentOnPass = () => (
  <Icon name='street view' size='huge' color='yellow' />
);

const PassDetailedMap = ({
  latlng,
  passRoute,
  showSatellite,
  maxZoom,
  minZoom,
  zoomLevel,
  title,
  msgToShow,
  showMarker,
  markerName,
  isLivePassMap
}) => {
  const typeMapID = showSatellite
    ? google.maps.MapTypeId.SATELLITE
    : google.maps.MapTypeId.DEFAULT;
  const options = {
    streetViewControl: false,
    scaleControl: false,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'of'
          }
        ]
      }
    ],
    gestureHandling: 'greedy',
    disableDoubleClickZoom: true,
    minZoom: minZoom || 12,
    maxZoom: maxZoom || 20,
    mapTypeControl: false,
    mapTypeId: typeMapID,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: google.maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE,
        google.maps.MapTypeId.HYBRID
      ]
    },

    zoomControl: true,
    clickableIcons: false
  };
  return (
    <Segment attached='top' style={{ padding: 0 }}>
      {title && (
        <Header as='h2' icon textAlign='center'>
          <Icon name='location arrow' color='teal' size='small' />
          {title}
          <Header.Subheader>{msgToShow || ''}</Header.Subheader>
        </Header>
      )}
      <div
        style={{
          height: `${isLivePassMap ? '750px' : '300px'}`,
          width: '100%'
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBcTMuuzfy_weX4lFZ1ypcf7F_Q8LnuSdo'
          }}
          defaultCenter={latlng || { lat: 25.852687, lng: -80.371362 }}
          defaultZoom={minZoom || 12}
          zoom={zoomLevel || 12}
          options={options}
        >
          
          {passRoute &&
            passRoute.map(routeLatLng => (
              <StudentOnPass
                key={`${routeLatLng.lat}-${routeLatLng.lng}`}
                lat={routeLatLng.lat}
                lng={routeLatLng.lng}
                text={showMarker ? markerName : 'Test'}
              />
            ))}
        </GoogleMapReact>
      </div>
    </Segment>
  );
};

export default PassDetailedMap;

import React from 'react';
import { Card, Header, Segment, Tab, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
// import InfiniteScroll from 'react-infinite-scroller';

const panes = [
  { menuItem: 'Recent Passes', pane: { key: 'recentPasses' } },
  { menuItem: 'Archive Passes', pane: { key: 'archivePasses' } },
  { menuItem: 'Active Passes', pane: { key: 'activePasses' } },
  { menuItem: 'Restroom Passes', pane: { key: 'restroomPasses' } }
];

const UserDetailedEvents = ({ passes, passesLoading, changeTab }) => {
  return (
    <Segment attached loading={passesLoading}>
      <Header icon='calendar' content='Passes' color='teal' />
      <Tab
        onTabChange={(event, data) => changeTab(event, data)}
        panes={panes}
        menu={{ secondary: true, pointing: true }}
      />
      <br />
      <Card.Group itemsPerRow={5}>
        {passes && passes.length === 0 && (
          <Header as='h1' color='red'>
            No Passes
          </Header>
        )}
        {passes &&
          passes.length !== 0 &&
          passes.map(pass => (
            <Card as={Link} to={`/passes/${pass.id}`} key={pass.id}>
              <Image
                src={`/assets/categoryImages/${pass.category}Chalkboard.jpg`}
              />
              <Card.Content>
                <Card.Header textAlign='center'>
                  {pass.departFrom} to {pass.destination}
                </Card.Header>
                <Card.Meta textAlign='center'>
                  <div>{format(pass.passStart.toDate(), 'dd LLL yyyy')} </div>
                  <div>{format(pass.passStart.toDate(), 'h:mm a')} </div>
                </Card.Meta>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
    </Segment>
  );
};

export default UserDetailedEvents;

import React, { Fragment } from 'react';
import { Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const PassDetailSidebar = ({ event }) => {
  return (
    <Fragment>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        secondary
        inverted
        color='black'
      >
        {event.studentName}
        {/* {attendees && attendees.length}{' '}
        {attendees && attendees.length === 1 ? 'Person' : 'People'} Going */}
      </Segment>
      <Segment attached>
        <Image
          as={Link}
          to={`/profile/${event.studentUID}`}
          size='small'
          circular
          src={event.studentPhotoURL}
        />
      </Segment>
    </Fragment>
  );
};

export default PassDetailSidebar;

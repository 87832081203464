import React from "react";

const ExtraCreditShop = () => {
  return (
    <div>
      <h1>Extra Credit Shop</h1>
    </div>
  );
};

export default ExtraCreditShop;

import { createReducer } from '../../app/common/util/readucerUtils';
import {
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  FETCH_PASSES,
  FETCH_USER_PASSES
} from './eventConstants';

const initialState = {
  passes: [],
  userPasses: []
};

const createEvent = (state, payload) => {
  return [...state, payload.event];
};

const updateEvent = (state, payload) => {
  return [
    ...state.filter(event => event.id !== payload.event.id),
    payload.event
  ];
};

const deleteEvent = (state, payload) => {
  return [...state.filter(event => event.id !== payload.eventId)];
};

const loadPasses = (state, payload) => {
  return { ...state, passes: payload.passes };
};

const loadUserPasses = (state, payload) => {
  return { ...state, userPasses: payload.passes };
};

export default createReducer(initialState, {
  [CREATE_EVENT]: createEvent,
  [UPDATE_EVENT]: updateEvent,
  [DELETE_EVENT]: deleteEvent,
  [FETCH_PASSES]: loadPasses,
  [FETCH_USER_PASSES]: loadUserPasses
});

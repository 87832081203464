import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { withFirestore, firebaseConnect, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import PassDetailHeader from './PassDetailHeader';
import PassDetailInfo from './PassDetailInfo';
import PassDetailChat from './PassDetailChat';
import PassDetailSidebar from './PassDetailSidebar';
// import { toastr } from 'react-redux-toastr';
// import { objectToArray } from '../../../app/common/util/helper';
import { goingToEvent, cancelGoingToEvent } from '../../user/userActions';
import { addPassComment } from '../eventActions';
import { objectToArray } from '../../../app/common/util/helper';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import NotFound from '../../../app/layout/NotFound';
import Footer from '../../nav/NavBar/Footer';

/*
TODO:
title: Student
category: destination
hostedBy: 

*/

const mapState = (state, ownProps) => {
  const passId = ownProps.match.params.id;

  // Create empty event so won't throw an error in case get to a wrong page
  let event = {};

  // Make sure got events
  if (
    state.firestore.ordered.passes &&
    passId &&
    state.firestore.ordered.passes.length > 0
  ) {
    event =
      state.firestore.ordered.passes.filter(event => event.id === passId)[0] ||
      {};
  }

  return {
    event,
    auth: state.firebase.auth,
    requesting: state.firestore.status.requesting,
    passChat:
      !isEmpty(state.firebase.data.pass_chat) &&
      objectToArray(state.firebase.data.pass_chat[ownProps.match.params.id])
  };
};

const actions = {
  goingToEvent,
  cancelGoingToEvent,
  addPassComment
};

class PassDetailedPage extends Component {
  async componentDidMount() {
    const { firestore, match } = this.props;

    // Listener
    await firestore.setListener(`passes/${match.params.id}`);

    // GET ONCE
    // const { firestore, match, history } = this.props;
    // let event = await firestore.get(`passes/${match.params.id}`);
    // Check EVent Exists, since User can type in anything for Event ID in Browser
    // if (!event.exists) {
    //   history.push('/passes');
    //   toastr.error('Sorry', 'Pass not found');
    // }
  }

  async componentWillUnmount() {
    const { firestore, match } = this.props;
    await firestore.unsetListener(`passes/${match.params.id}`);
  }

  render() {
    const {
      event,
      auth,
      goingToEvent,
      cancelGoingToEvent,
      addPassComment,
      passChat,
      requesting,
      match
    } = this.props;
    const myPassRequest = event.studentUID === auth.uid;
    const isTeacher2Approve = event.departFromTeacherUId === auth.uid;
    const loadingPass = requesting[`passes/${match.params.id}`];
    // const attendees = event && event.attendees && objectToArray(event.attednees);
    // Some returns T/F if has a property comparing
    // const isGoing = attendees && attendees.some(a => a.id === auth.uid)

    if (loadingPass) return <LoadingComponent />;

    // Check if User  user typed in address to empty event
    if (Object.keys(event).length === 0) return <NotFound />;

    return (
      <Fragment>
        <Grid>
          <Grid.Column width={10}>
            <PassDetailHeader
              event={event}
              isMyPass={myPassRequest}
              isTeacher2Approve={isTeacher2Approve}
              goingtoEvent={goingToEvent}
              cancelGoingToEvent={cancelGoingToEvent}
            />
            <PassDetailInfo event={event} />
          </Grid.Column>
          <Grid.Column width={6}>
            <PassDetailSidebar event={event} />
            <PassDetailChat
              addPassComment={addPassComment}
              passId={event.id}
              passChat={passChat}
              isPassActive={
                event.endTime && event.endTime === '' ? true : false
              }
            />
            {/* TODO: ADD BASIC STUDENT INFO HERE */}
          </Grid.Column>
        </Grid><br/>
        <Footer />
      </Fragment>
    );
  }
}

export default compose(
  withFirestore,
  connect(
    mapState,
    actions
  ),
  firebaseConnect(props => [`pass_chat/${props.match.params.id}`])
)(PassDetailedPage);

// const newEvent = {
//   ...values,
//   id: cuid(),
//   departFrom: 'departFrom',
//   departTeacherPhotoURL: '/assets/user.png'
//   // TODO: ADD SCHOOL INFORMATION AUTOMATICALLY HERE SINCE KNOWN
// };

export const createNewPass = (user, schoolName, photoURL, event) => {
  return {
    ...event,
    studentUID: user.uid,
    studentName: user.displayName,
    studentPhotoURL: photoURL || '/assets/user.png',
    schoolName: schoolName,
    passStart: new Date(Date.now())
    // attenddes: {
    //   [user.uid]: {
    //     going: true,
    //     joinDate: new Date(),
    //     photoURL: photoURL || '/assets/user.png',
    //     displayName: user.displayName,
    //     host: true
    //   }
    // }
  };
};

export const objectToArray = object => {
  if (object) {
    // Get new object with all properities in object with the ID
    return Object.entries(object).map(e =>
      Object.assign({}, e[1], { id: e[2] })
    );
  }
};

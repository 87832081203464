import React, { Component } from 'react';
import {
  Grid,
  Header,
  Icon,
  Divider,
  Segment,
  Item,
  List,
  Image
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import ContactInfo from './contactus/ContactInfo';
import FollowUs from './FollowUs';
import { Link } from 'react-router-dom';
import ContactForm from './contactus/ContactForm';
import { addComment } from '../user/userActions';
import SloganTeacher from './slogan/SloganTeacher';
import Footer from '../nav/NavBar/Footer';

const mapState = (state, ownProps) => ({
  profile: state.firebase.profile
});

const actions = {
  addComment
};

class FAQ extends Component {
  render() {
    const { profile } = this.props;
    const isLoggedIn = !profile.isEmpty;
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header size='huge'>
            <Icon name='lightbulb outline' color='red' />
            FAQ / Help
          </Header>
          <Divider />
          <Segment>
            <SloganTeacher /> <Divider />
            <Header>FREQUENTLY ASKED QUESTIONS</Header>
            Below are some frequently asked questions and answers.
            <Item.Group>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>How Does it Work?</Item.Header>
                  <Item.Meta>
                    Is it complicated to use inside my classroom?
                  </Item.Meta>
                  <Item.Description>
                    Remember, I'm a high school computer science teacher, so i
                    know that we have enought on our plates to add something
                    else. So I coded this with Teacher's in mind to make it easy
                    to use in your classroom. Essentially, a student can use
                    their cell phone or this website to request permission to
                    leave their teacher's classroom. School Pass Live! will send
                    the request to the teacher for them to approve. When the
                    student returns, their pass is saved in the Pass History.
                  </Item.Description>
                  <Item.Extra>
                    It is a streamlined digital version of the traditional
                    school hall pass
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>
                    Do you have Real Time Monitoring Locations Outside of
                    School?
                  </Item.Header>
                  <Item.Meta>
                    Can you help me find my child outside of school?
                  </Item.Meta>
                  <Item.Description>
                    <strong>
                      <em>NO!!! </em>
                    </strong>{' '}
                    Real-time monitoring of students occurs{' '}
                    <strong>
                      <em>at a school only</em>
                    </strong>
                    . In addition, it only monitors students when they request
                    to leave a teacher's supervision and classroom to go to
                    another location. Real-time monitoring stops when they
                    return to that teacher's supervision in that classroom.
                  </Item.Description>
                  <Item.Extra>
                    Our 1st duty is to teach, and our 2nd is to monitor students
                    at all times to keep them safe at school. This is what
                    School Pass Live! does using the latest technology
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>Is A Mobile Device Needed?</Item.Header>
                  <Item.Meta>
                    What if my school doesn't allow students to use their cell
                    phones?
                  </Item.Meta>
                  <Item.Description>
                    In my classroom, not every high school student has a mobile
                    device, so a mobile device is not needed. You can still use
                    School Pass Live! inside your classroom or school. There are
                    a couple of options that you can use instead.
                    <List bulleted>
                      <List.Item>
                        <strong>
                          {' '}
                          <Link to='/sharedClassPass'>CLASS PASS MODE</Link>:
                        </strong>
                        <br /> A teacher can use one mobile device as a{' '}
                        <Link to='/sharedClassPass'>shared class pass</Link>
                        for their classroom. Many districts provide mobile
                        devices for educational purposes. This still provides
                        real time monitoring of a student when they leave a
                        classroom at school. See{' '}
                        <Link to='/sharedClassPass'>
                          Class Pass for more information
                        </Link>
                      </List.Item>
                      <List.Item>
                        <strong>
                          <Link to='/createPass'>
                            SCHOOLPASSLIVE.COM PASS REQUEST FORM:
                          </Link>
                        </strong>
                        <br /> A teacher can have their students use{' '}
                        <Link to='/createPass'>this Pass Request form </Link> to
                        have students leave & return to their classroom. This{' '}
                        <strong>
                          <em>DOES NOT</em>
                        </strong>{' '}
                        provide real time monitoring of a student when they
                        leave a classroom at school, however, it does provide
                        access to Pass History and other features of School Pass
                        Live!.
                      </List.Item>
                    </List>
                  </Item.Description>
                  <Item.Extra>
                    {' '}
                    School Pass Live! is made for every classroom, every
                    teacher, every student regardless of having access to a
                    mobile device
                  </Item.Extra>
                </Item.Content>
              </Item>

              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>How Much Does This Cost?</Item.Header>
                  <Item.Meta>
                    How can I help bring this to my classroom or school?
                  </Item.Meta>
                  <Item.Description>
                    School Pass Live! is <strong>FREE</strong> to create
                    accounts and use at any classroom or school. As a teacher, I
                    can't afford to pay the cost to run School Pass Live!, but I
                    also know schools & teachers's don't have excess money to
                    spend either.
                    <p>
                      Each pass in our system is a commodity (
                      <em>just like a traditional paper pass</em>), there is a
                      cost to each pass. Each school is given 200 school shared
                      passes to share amongst all classrooms, and each teacher
                      is given 200 passes for their classroom only. Once they
                      run out of FREE passes, they can purchase additional
                      passes through the Extra Credit Shop.
                    </p>
                  </Item.Description>
                  <Item.Extra>
                    It's FREE!!! Schools/Teachers can purchase additional passes
                    if they run out.
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>Who Can Use School Pass Live!?</Item.Header>
                  <Item.Meta>What age Group are you targeting?</Item.Meta>
                  <Item.Description>
                    I originally built School Pass Live! for my high school
                    classroom. However, many teachers and principals asked me to
                    share and make this better for everyone. So it is now for
                    any school, teacher, classroom or district can now use it.
                    It is Free to help all schools have "Safer, Easier, Smarter,
                    Passes For Classes"
                  </Item.Description>
                  <Item.Extra>
                    School Districts, Principals, Administration, Teachers,
                    Students, Security Guards, Police, Firemen
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>
                    How Can I View Student Pass History & Archives?
                  </Item.Header>
                  <Item.Meta>
                    Can I search by student to see how many times they've left
                    my classroom?
                  </Item.Meta>
                  <Item.Description>
                    Yes, in my classroom, I had some chronic students who
                    requested to go to the restroom, but would come back with
                    chips from the vending machine. So I built in Pass History
                    to search student pass archives to help teachers deal with
                    this same issue.
                  </Item.Description>
                  <Item.Extra>
                    You can view each student pass history including location
                    they went while on a pass.
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>
                    Difference between School Shared & Classroom Only Pass
                  </Item.Header>
                  <Item.Meta>Aren't they all just passes anyway?</Item.Meta>
                  <Item.Description>
                    <em>School shared passes</em> can be used by any classroom
                    by anyone at the school. <em>Classroom only passes</em>
                    can be used by one teacher's classroom only for only their
                    pass requests. For example, my classroom Room 102, has 200
                    passes for my students only. If no classroom passes exist
                    for a classroom, then the school shared passes will be used.
                  </Item.Description>
                  <Item.Extra>
                    Simply put, School Shared is for entire school vs. Classroom
                    Only is just for my classroom students.
                  </Item.Extra>
                </Item.Content>
              </Item>
              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>Can I set up Classroom Rooms?</Item.Header>
                  <Item.Meta>
                    Modifying rules or each teacher classroom like pass limits?
                  </Item.Meta>
                  <Item.Description>
                    Yes, you can set up your individual classroom rules inside
                    your Educator Settings. For example, student pass limits can
                    be set per week or month. For example, 2 passes a week or 2
                    passes a day, it is entirely up to you to set up your
                    classroom pass rules.
                  </Item.Description>
                  <Item.Extra>
                    You can view each student pass history including location
                    they went while on a pass.
                  </Item.Extra>
                </Item.Content>
              </Item>

              <Item>
                <Icon name='help circle' size='huge' color='teal' />
                <Item.Content>
                  <Item.Header>What Is The Extra Credit Shop?</Item.Header>
                  <Item.Meta>
                    What are Extra Credit Points? How do I get them?
                  </Item.Meta>
                  <Item.Description>
                    Extra Credit points can be earned throughout School Pass
                    Live! on the mobile app. They are hidden throughout but can
                    be redeemed for things inside the Extra Credit Shop on the
                    mobile device. Our Extra Credit Shop is where you can get in
                    app purchases to unlock additional features.
                  </Item.Description>
                  <Item.Extra>
                    Everyone loves Extra Credit at School, so you can get and
                    redeem Extra Credit here.
                  </Item.Extra>
                </Item.Content>
              </Item>
            </Item.Group>
          </Segment>
        </Grid.Column>
        <Grid.Column width={6}>
          <ContactForm
            profile={profile}
            isLoggedIn={isLoggedIn}
            addComment={addComment}
            bgSize='contain'
          />
          <ContactInfo />
          <FollowUs />
          <br />
          <Image src='/assets/phonePreview/menuClassPassXL.png' />
        </Grid.Column>
        <Grid.Column width={16}>
          <Footer />
        </Grid.Column>
      </Grid>
    );
  }
}

export default connect(
  mapState,
  actions
)(FAQ);

import { toastr } from 'react-redux-toastr';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../async/asyncActions';
import { FETCH_USER_PASSES } from '../event/eventConstants';
import firebase from '../../app/config/firebase';
import { format } from 'date-fns';

// Update the Local Store Profile in Firestore
export const updateProfile = user => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  // Get all fields from user except isLoaded & isEmpty
  const { isLoaded, isEmpty, ...updatedUser } = user;

  try {
    await firebase.updateProfile(updatedUser);
    toastr.success('Success', 'Profile updated');
  } catch (error) {
    console.log(error);
  }
};

export const uploadProfileImage = file => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const imageName = 'selfie.jpg';
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const selfiePath = `selfies/${user.uid}/user_images`;
  const options = {
    name: imageName // use imageName for duplicate Photos
  };

  try {
    dispatch(asyncActionStart());

    // Get User Document from Firestore to add photo to/check if has a photo
    // let userDoc = await firestore.get(`users/${user.uid}`);
    // let userHasPhoto = userDoc.data().photoURL;

    // // Firebase Doesn't like same Name Files
    // if (userHasPhoto) {
    //   // Delete File first. Then add
    //   await firebase.child(`${selfiePath}${imageName}`).delete();
    // }

    // Upload file to Firebase Storage
    let uploadedFile = await firebase.uploadFile(
      selfiePath,
      file,
      null,
      options
    );

    // Get the URL Back from that Image
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

    await firebase.updateProfile({
      photoURL: downloadURL
    });
    // Update FB Auth Sections for Photos
    await user.updateProfile({
      photoURL: downloadURL
    });

    dispatch(asyncActionFinish);
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError);
  }
};

// export const deletePhoto = photo => async (
//   dispatch,
//   getState,
//   { getFirebase, getFirestore }
// ) => {
// const firebase = getFirebase();
// const firestore = getFirestore();
// const user = firebase.auth().currentUser;

// try {
// await firebase.deleteFile(`${user.uid}/user_images/${photo.name}`);
// await firestore.delete({
//   collection: 'users',
//   doc: user.uid,
//   subcollections: [{ collection: 'photos', doc: photo.id }]
// });
//   } catch (error) {
//     console.log(error);
//     throw new Error('Problem deleting photo');
//   }
// };

// export const setMainPhoto = photo => async (
//   dispatch,
//   getState,
//   { getFirebase }
// ) => {
//   const firebase = getFirebase();

//   try {
//     return await firebase.updateProfile({
//       photoURL: photo.url
//     });
//   } catch (error) {
//     console.log(error);
//     throw new Error('Problem setting main photo');
//   }
// };

export const goingToEvent = event => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const profile = getState().firebase.profile;

  const attendee = {
    going: true,
    joinDate: firestore.FieldValue.serverTimestamp(),
    photoURL: profile.photoURL || '/assets/user.png',
    displayName: profile.displayName,
    host: false
  };

  try {
    // update Firestore by adding in the attendee information
    await firestore.update(`passes/${event.id}`, {
      [`attendees.${user.uid}`]: attendee
    });

    // Add duplicate information to lookup table for querying later
    await firestore.set(`passes_attendee/${event.id}_${user.uid}`, {
      eventId: event.id,
      userUid: user.uid,
      eventDate: event.date,
      host: false
    });

    toastr.success('Success', 'You have signed up for the event');
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Could not sign you up. Please try again');
  }
};

// UPDATE THIS TO CANCEL GOING ON PASS for STUDENTS
export const cancelGoingToEvent = event => async (
  dispatch,
  getState,
  { getFirestore, getFirebase }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;

  try {
    await firestore.update(`passes/${event.id}`, {
      // Use FieldValue.delete() to remove specific field in a document
      // Delete the attendee with the key of the User ID to erase them
      // redux_firestore 0.32 (When you delete this, it doesn't remove from redux so don't use 0.8 until version 1.0)
      [`attendees.${user.uid}`]: firestore.FieldValue.delete()
    });

    await firestore.delete(`event_attendee/${event.id}_${user.uid}`);
    toastr.success('Success', 'You have removed yourself form the event');
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Something went wrong');
  }
};

export const getUserPasses = (userUid, activeTab, lastPass) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();
  const today = new Date();
  let passesRef = firestore.collection('passes');
  let query;

  switch (activeTab) {
    case 1: // past passes
      query = passesRef
        .where('studentUID', '==', userUid)
        .where('passStart', '<=', today)
        .orderBy('passStart', 'desc');
      break;
    case 2: // current passes
      query = passesRef
        .where('studentUID', '==', userUid)
        .where('passStart', '>', today)
        // .where('isTrackingPass', '==', false)
        .orderBy('passStart');
      break;
    case 3: // restroom passes
      query = passesRef
        .where('studentUID', '==', userUid)
        .where('destination', '==', 'Restroom')
        // .where('isTrackingPass', '==', false)
        .orderBy('passStart')
        .limit(5);
      break;
    default:
      query = passesRef
        .where('studentUID', '==', userUid)
        .orderBy('passStart', 'desc')
        .limit(5);
      break;
  }

  // PAGING: 5 passes at a time. Get Last Event in passes
  let startAfter = lastPass && (await passesRef.doc(lastPass.id).get());

  // last pass
  if (lastPass) {
    query.startAfter(startAfter);
  }

  try {
    let querySnapshot = await query.get();
    let passes = [];

    // Loop over querySnapshot and store into our eventRedducer
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let pass = {
        ...querySnapshot.docs[i].data(),
        id: querySnapshot.docs[i].id
      };
      passes.push(pass); // Add to Events to Return
    }

    dispatch({ type: FETCH_USER_PASSES, payload: { passes } });
    dispatch(asyncActionFinish());

    // FOR LOOKUP TABLE
    // let passes = [];
    // for (let i = 0; i < querySnapshot.docChanges.length; i++) {
    //   let pass = await firestore
    //     .collection('passes')
    //     .doc(querySnapshot.docs[i].passId.get());
    //   passes.push({ ...pass.data(), id: pass.id });
    // }

    // dispatch({ type: FETCH_PASSES, payload: { passes } });
    return passes;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const addComment = (path, values) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const profile = getState().firebase.profile;
  const user = firebase.auth().currentUser;

  let now = format(Date.now(), 'EEE LLL do yyyy h:mm a');

  // Get Values for Comment
  // if Logged In, form didnt have name,other values so get those from profile
  let newComment = profile.isEmpty
    ? {
        displayName: values.fullName,
        email: values.email,
        phone: values.phone || '',
        schoolName: values.school || '',
        city: values.city || '',
        text: values.comment,
        howHeardAbout: values.howDidHearAboutUs || '',
        date: now
      }
    : {
        displayName: profile.displayName,
        email: user.email,
        phone: profile.phone,
        schoolName: profile.schoolName,
        city: profile.city,
        text: values.comment,
        photoURL: profile.photoURL || '/assets/user.png',
        uid: user.uid,
        date: now
      };

  try {
    dispatch(asyncActionStart());
    await firebase.push(path, newComment);
    toastr.success('Success', 'Sent Message to Teacher');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Probelm adding Comment');
    dispatch(asyncActionError());
  }
};

export const addSchoolClassroom = values => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const profile = getState().firebase.profile;
  const user = firebase.auth().currentUser;

  let now = format(Date.now(), 'EEE LLL do yyyy h:mm a');

  // Add Logged In User Info
  let path = '/test/addClassrooms';

  // Get Values for Comment
  // if Logged In, form didnt have name,other values so get those from profile
  let newClassroom = {
    submittedBy: profile.displayName,
    email: user.email,
    phone: profile.phone || '',
    schoolName: profile.schoolName || '',
    city: profile.city,
    classroom: values.classroomName || values.category,
    category: values.category,
    classroomTeacher: values.classroomTeacher || '',
    // classroomCoords: values.coordinates || profile.school.coordinates,
    uid: user.uid,
    date: now
  };

  try {
    dispatch(asyncActionStart());
    await firebase.push(path, newClassroom);
    toastr.success('Success', 'Sent Message to Teacher');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Probelm adding Comment');
    dispatch(asyncActionError());
  }
};

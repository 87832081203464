import React, { Component, Fragment } from 'react';
import { Form, Button, Divider, Segment, Image } from 'semantic-ui-react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import chalkboard from '../../../srcAssets/chalkboard/chalkboardLong.jpg';
import { addSchoolClassroom } from '../../user/userActions';
import TextInput from '../../../app/common/form/TextInput';
import SelectInput from '../../../app/common/form/SelectInput';
import { toastr } from 'react-redux-toastr';

const mapState = state => {
  const selector = formValueSelector('addClassroom');
  let selectedDestination = selector(state, 'category');
  let notDefaultSchool =
    selectedDestination && selector(state, 'category') === 'Classroom';

  return {
    profile: state.firebase.profile,
    needClassAndNameTeacher: notDefaultSchool
  };

  // Map isDestinationAClassroom
};

const actions = {
  addSchoolClassroom
};

const defaultLocations = [
  { key: 'restroom', text: 'Restroom', value: 'Restroom' },
  { key: 'auditorium', text: 'Auditorium', value: 'Auditorium' },
  { key: 'cafeteria', text: 'Cafeteria', value: 'Cafeteria' },
  { key: 'classroom', text: 'Classroom', value: 'Classroom' },
  { key: 'clinic', text: 'Clinic', value: 'Clinic' },
  { key: 'counselor', text: 'Counselor', value: 'Counselor' },
  { key: 'gymnasium', text: 'Gymnasium', value: 'Gymnasium' },
  { key: 'library', text: 'Library', value: 'Library' },
  { key: 'office', text: 'Main Office', value: 'Office' }
];

class AddClassroomsForm extends Component {
  onFormSubmit = values => {
    const { addSchoolClassroom, reset, needClassAndNameTeacher } = this.props;

    values.category = !needClassAndNameTeacher
      ? values.category.toLowerCase()
      : values.category;

    // Update Category to lowercase if Default Classroom
    if (needClassAndNameTeacher) {
      // Check Entered Class name
      if (!values.className || values.className === '') {
        toastr.error('Oops', 'Please enter a Classroom Name');
        return;
      }

      // Check Profanity List TODO
    }

    // Check if Not Default Classroom, verify you have a className at least

    addSchoolClassroom(values);
    reset();
  };

  render() {
    const {
      pristine,
      submitting,
      profile,
      handleSubmit,
      needClassAndNameTeacher
    } = this.props;
    return (
      <Segment.Group>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${chalkboard})`,
            padding: '30px'
          }}
        >
          <Image
            bordered
            circular
            size='small'
            centered
            src='/assets/team/askJamieClassroom.jpg'
          />{' '}
          Add a new classroom to your school.
          <Divider />
          <p>
            <span style={{ color: 'cyan' }}>
              <strong>{profile.displayName}</strong>
            </span>{' '}
            <br />{' '}
            <span style={{ color: 'pink' }}>
              <em>{profile.schoolName}</em>
            </span>{' '}
          </p>
          <Divider />
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Field
              name='category'
              component={SelectInput}
              options={defaultLocations}
              placeholder='Select Classroom Type'
            />
            {needClassAndNameTeacher && (
              <Fragment>
                <Field
                  width={5}
                  name='classroomName'
                  autoComplete='off'
                  type='text'
                  component={TextInput}
                  placeholder='Classroom Name'
                />
                <Field
                  width={5}
                  name='classroomTeacher'
                  autoComplete='off'
                  type='text'
                  component={TextInput}
                  placeholder='Classroom Teacher Name'
                />
              </Fragment>
            )}
            <Button
              disabled={submitting || pristine}
              content='Send To Teacher'
              labelPosition='left'
              color='red'
              icon='edit'
            />
          </Form>
        </Segment>
        <Segment
          textAlign='center'
          inverted
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'http://schoolpasslive.com/images/chalkboardBlackFtr.jpg'})`
          }}
        />
      </Segment.Group>
    );
  }
}

export default connect(
  mapState,
  actions
)(
  reduxForm({
    form: 'addClassroom',
    destroyOnUnmount: true
  })(AddClassroomsForm)
);

import React, { Fragment } from 'react';
import { Button,  Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SchoolInfo from '../../school/schoolInfo/SchoolInfo';

const UserDetailedSidebar = ({ isCurrentUser }) => {
  return (
    <Fragment>

<Segment>
        {isCurrentUser ? (
          <Button
            as={Link}
            to={'/settings'}
            color='teal'
            fluid
            basic
            content='Edit Profile'
          />
        ) : (
          <Button color='teal' fluid basic content='Follow User' />
        )}
      </Segment>

      <SchoolInfo />
    </Fragment>
  
  );
};

export default UserDetailedSidebar;

import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from '../../../app/common/form/TextArea';

class PassDetailedChatForm extends Component {
  handleCommentSubmit = values => {
    const { addPassComment, reset, passId } = this.props;
    addPassComment(passId, values);
    reset();
  };

  render() {
    const { isPassActive } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit(this.handleCommentSubmit)}>
        <Field name='comment' type='text' component={TextArea} rows={2} />
        {isPassActive && (
          <Button
            disabled={isPassActive}
            content='Send Message'
            labelPosition='left'
            icon='edit'
            primary
          />
        )}
      </Form>
    );
  }
}

export default reduxForm({ Fields: 'comment' })(PassDetailedChatForm);

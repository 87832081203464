import React from 'react';
import { Image } from 'semantic-ui-react';

const ExamplePass = () => {
  return (
    <div>
      <Image src='/assets/traditionalPass/passMdpsXL' />
    </div>
  );
};

export default ExamplePass;

import React, { Fragment } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import FollowUs from '../../about/FollowUs';

const SettingsNav = () => {
  return (
    <Fragment>
      <Menu vertical>
        <Header
          icon='user'
          attached
          inverted
          color='black'
          content='Profile'
          className='sidebarHeader'
        />
        <Menu.Item as={NavLink} to={'/settings/about'}>
          About Me
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/settings/school'}>
          My School
        </Menu.Item>
        <Menu.Item as={NavLink} to={'/settings/photos'}>
          My Photos
        </Menu.Item>
      </Menu>
      <Menu vertical>
        <Header
          icon='settings'
          attached
          inverted
          color='grey'
          content='Account'
        />
        <Menu.Item as={NavLink} to={'/settings/account'}>
          My Account
        </Menu.Item>
      </Menu>{' '}
      <FollowUs />
    </Fragment>
  );
};

export default SettingsNav;
